@import "../../../assets/scss/variables.scss";

.toolbarViewerSpacerRight {
  margin-right: $spacer;
}

.docGridViewSection {
  padding: 1rem 0 0 0.5rem !important;
  .col:nth-child(1n),
  .col:nth-child(n + 1) {
    padding-right: 0.5rem;
  }
}

.instantUploadDiv {
  bottom: 9%;
  left: 0;
  display: flex;
  right: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.instantUploadIcon {
  background-color: $primary;
  border-radius: $border-radius-lg;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  width: 250px;
  height: 72px;
  overflow: hidden;
}

.instantUploadIconText {
  font-size: 18px;
  color: $white;
}

.uploadImage {
  color: $gray-800;
  width: 75px;
  height: 50px;
}

.dropableDivImage {
  background-position: center;
  background-repeat: no-repeat;
  background-color: $white;
}

.list-view .react-bs-container-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: $white;
}
.list-view {
  padding-top: 0 !important;
}

.noDocumentsDivImage {
  background-position: center;
  background-repeat: no-repeat;
  background-color: $gray-100;
}

.pdfViewerWrapper {
  border: 1px solid $gray-400;
  border-radius: $border-radius-lg;
  height: calc(100vh - 200px);
  box-shadow: none;
}
