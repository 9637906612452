@import "../../../assets/scss/variables.scss";

.unitStatusHeader {
  margin: 0;
  padding-right: 2%;
  div {
    padding: 0.6rem 0.3rem;
    white-space: nowrap;
  }
  .documentStatusHeader {
    width: 23%;
  }
  .marketHeader {
    width: 20%;
  }
  .monthlyHeader {
    width: 20%;
  }
  .unitHeader {
    width: 13%;
  }
}

.unitStatus {
  overflow: auto;
  height: calc(100vh - 302px);
  width: 98%;
  flex-grow: 0;
  flex-basis: auto;
  .table {
    margin-bottom: 0px;
  }
  table {
    margin-bottom: 0px;
    td {
      padding: 0.4em 0.3rem;
      font-size: $font-size-xsmall;
      line-height: 16px;
      width: auto;
      vertical-align: middle;
      border-top: none;
      background-color: $white;
      border-bottom: (1 * $table-border-width) solid $table-border-color;
    }
    .widthId {
      width: 30px;
    }
    .documentStatusLabel {
      width: 23%;
      white-space: pre-line;
    }
    .marketLabel {
      width: 20%;
    }
    .monthlyLabel {
      width: 20%;
    }
    .unitLabel {
      width: 13%;
      small {
        display: block;
        color: $primary;
      }
    }
  }
}

.unitStatusFooter {
  > :not(:last-child) {
    margin-right: 0.55rem;
  }
}

.unitStatusChartWrapper {
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100vh - 263px);
  width: 100%;
  margin: 0 auto;
  flex-grow: 0;
  padding: 10px;
  flex-basis: auto;
  svg {
    font-size: $font-size-msmall;
  }
}

// @media only screen and (min-width: 1600px) and (max-width: 1680px) {
//   .unitStatusHeader {
//     .marketHeader {
//       width: 100px;
//     }
//     .unitHeader {
//       width: 90px;
//     }
//   }
//   .unitStatus {
//     table {
//       .marketLabel {
//         width: 94px;
//       }
//       .unitLabel {
//         width: 62px;
//       }
//     }
//   }
// }

// @media only screen and (max-width: 1440px) {
//   .unitStatusHeader {
//     .marketHeader {
//       width: 90px;
//     }
//     .unitHeader {
//       width: 62px;
//     }
//   }
//   .unitStatus {
//     table {
//       .marketLabel {
//         width: 94px;
//       }
//       .unitLabel {
//         width: 60px;
//       }
//     }
//   }
// }

// @media only screen and (min-width: 1360px) and (max-width: 1366px) {
//   .unitStatusHeader {
//     .marketHeader {
//       width: 85px;
//     }
//     .unitHeader {
//       width: 62px;
//     }
//   }
//   .unitStatus {
//     table {
//       .monthlyLabel {
//         width: 86px;
//       }
//       .marketLabel {
//         width: 95px;
//       }
//       .unitLabel {
//         width: 58px;
//       }
//     }
//   }
// }

// @media only screen and (min-width: 1200px) and (max-width: 1280px) {
//   .unitStatusHeader {
//     .marketHeader {
//       width: 88px;
//     }
//     .unitHeader {
//       width: 62px;
//     }
//   }
//   .unitStatus {
//     table {
//       .marketLabel {
//         width: 92px;
//       }
//       .unitLabel {
//         width: 58px;
//       }
//     }
//   }
//   .unitStatusChartWrapper {
//     overflow-x: scroll;
//   }
// }

// @media only screen and (max-width: 1024px) {
//   .unitStatusChartWrapper {
//     overflow-x: scroll;
//   }
// }
