@import "../../../assets/scss/variables.scss";

.documentFileDragdrop {
  padding: $spacer * 2;
  .documentFileIcon {
    img {
      width: 60px;
    }
  }
  h1 {
    font-size: $h2-font-size;
    font-weight: $font-weight-light;
    padding: $spacer 0rem $spacer / 2;
    margin-bottom: 0rem !important;
  }
  p {
    font-size: $font-size-base;
    margin-bottom: $spacer / 3 !important;
    strong {
      font-weight: $font-weight-medium;
    }
  }
  small {
    font-size: $font-size-xsmall;
  }
}
