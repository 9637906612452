.accountSettingsHeader {
  padding: 0.5rem 0.9rem !important;
}
.wrapperScroll {
  overflow: hidden;
}
.pageOuterSection {
  height: auto;
}
.sectionHeader {
  height: 104px;
  color: rgba(0,0,0,0.87);
  font-weight: 500;
  font-size: 24px;
  line-height: 17px;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500; 
  align-items: center;
  padding: 0 5rem;  
  span {
    font-size: 16px;
    color: #868686;
    margin-left: 0.5rem;
  }
}
.sidebarLeftCol {
  width: 300px;
  height: calc(100vh - 80px);
  display: flex;
  padding: 1.7rem 0 0 0  !important;
  flex-direction: column;
  left: 0;
  position: -webkit-sticky;
  position: sticky;
  padding: 30px 15px 15px;
  z-index: 1;
  background-color: #F7F7F7;
  .settingNavLinkBtn {
    font-size: 0.9rem;
    text-align: left !important;
    font-weight: 400 !important;
    padding: 1.1rem 1.4rem;
    border-right: 4px solid #F7F7F7;
    background-color: #F7F7F7;
    font-style: normal;
    font-weight: normal;
    font-size: 1.1rem;
    line-height: 17px;
    border-color: #F7F7F7;
    color: #000000;
    &.disabled,
    &:disabled {
      background-color: #fff;
      border-right: 4px solid #145f97;
      opacity: 1;
      color: #145f97;
    }
    &:active {
      border-color: #fff !important;
    }
    &:hover, &:active {
      border-right: 4px solid #145f97;
      background-color: #fff !important;
      color: #145f97 !important;
    }
    .navIcons {
      width: 30px;
      padding-right: 5px;
      position: relative;
      top: -2px;
      img {
        width: 18px;
        
      }
    }
  }
  
}

.backButton{
  margin-bottom: 2rem;
  color:#606060;
  padding: 1rem;
  display: flex;
  padding: 10px;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
}

.backButtonIcon{
  margin-right: 10px;
  margin-left: 10px;
}