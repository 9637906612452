@import "../../../assets/scss/variables.scss";
.formCard {
  width: 350px;
  background: none;
  margin: 5% auto 0;
  border: none;
  text-align: center;
}

.formCardBody {
  font-size: $font-size-default;
  .btnWarning {
    display: block;
    margin: auto;
    padding: 0.25rem 0.75rem;
  }
  small {
    font-size: 98%;
  }
  .formControl {
    height: calc(1.7em + 0.75rem + 2px);
    padding-left: 2.3rem;
  }
  button {
    padding: 0px;
    margin: 0px;
  }
  .btnPrimary {
    padding: 0.5rem 0.75rem;
  }
  svg {
    position: relative;
    left: 4px;
  }
  .socialSignIn {
    flex-direction: column;
    > div {
      width: 100% !important;
      margin-right: 0;
      margin-bottom: 1rem;
      position: relative;
      img {
       margin-left: 8px !important;
      }
    }
  }
  .formSeparator {
    display: block;
    position: relative;
    height: 30px;
    margin-top: 15px;
    small {
      height: 1px;
      background: #ddd;
      width: 100%;
      position: absolute;
    }
    span {
        background-color: #fff;
        position: absolute;
        width: 150px;
        top: -10px;
        left: 0;
        margin: 0 auto;
        right: 0;
        padding: 0 10px;
        text-align: center;
    }
  }
}
