@import "../../../assets/scss/variables.scss";

$primary-btn-color: $primary;
$light-blue: $light-blue;

.tagDocument-paneHeader {
    width: 100%;
    padding: 6px 10px;
    border-bottom: 1px solid $gray-400;
    background-color: $white;
    border-top-left-radius: $border-radius-lg;
    border-top-right-radius: $border-radius-lg;
    h3 {
        font-size: 1.2rem;
        margin: 0rem;
        padding: 0rem;
    }
}

.addIconButton {
    width: 30px;
    height: 30px;
    line-height: 28px;
    text-align: center;
    background-color: transparent;
    border-color: transparent;
    border-radius: $rounded-pill !important;
    &.deleteIconButton {
        width: 28px !important;
        height: 28px !important;
    }
    &.btn {
        padding: 0px !important;
        margin: 0px !important;
    }
    &:hover,
    &:focus,
    &:active {
        background-color: $gray-500;
        border-color: transparent;
        cursor: pointer;
    }
}

.taggingDocument-wrapper {
    height: calc(100vh - 328px);
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    z-index: 9;
    .form-group label {
        font-size: $font-size-xsmall;
        color: $gray-700;
    }
    .form-control {
        height: auto;
        padding: 0.2rem 0.75rem;
    }
    .form-group {
        margin-bottom: 0.8rem;
    }
    .btn {
        padding: 0.2rem 0.75rem;
    }
}

.tagDocAccordion {
    padding-bottom: 0.5rem;
    .card {
        margin-bottom: 0rem;
        border-radius: 0rem !important;
        border: 1px solid $gray-400 !important;
        border-top: 0rem !important;
        border-right: 0rem !important;
        border-left: 0rem !important;
        overflow: unset;
    }
    .card-header {
        display: flex;
        align-items: center;
        padding: 0.4rem 0.7rem 0.4rem 1.4rem;
        background-color: transparent;
        border-bottom: 0rem !important;
        &:first-child {
            border-radius: 0rem;
        }
    }
    .card-body {
        padding: 0.4rem 0.7rem;
        margin: 0.1rem 0.5rem 0.5rem;
        border: 1px solid $gray-400;
        border-radius: $border-radius-xlg;
    }
}

.accordion-button {
    border: none;
    padding: 0rem !important;
    background-color: transparent;
    color: $gray-900;
    &:hover,
    &:focus {
        color: $gray-900;
        text-decoration: none;
    }
}

.rstcustom-expandButton,
.rstcustom-collapseButton {
    position: relative;
    top: 0;
    width: 100%;
    text-align: left;
    font-weight: $font-weight-bold;
    transform: translate(0%, 0%);
    &:before {
        content: "";
        position: absolute;
        left: -0.5rem;
        top: 50%;
        width: 12px;
        height: 12px;
        transform: translate(-10%, -50%);
        background-image: url(../../../assets/images/right-arrow.svg);
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.rstcustom-collapseButton:before {
    transform: rotate(90deg);
    top: 36%;
    left: -0.5rem;
}

.badges-tags {
    padding-left: 1rem;
    span {
        font-size: $font-size-xsmall;
        font-weight: $font-weight-medium;
        color: $primary-btn-color;
        padding: 0.125rem 0.7rem 0.2rem;
        margin: 0 0.1rem;
        display: inline-block;
        border-radius: $border-radius-md;
        background-color: $light-blue;
    }
}

.tagLabel-heading {
    font-size: $font-size-default;
    font-weight: $font-weight-medium;
    margin-bottom: 0.2rem;
}

.asOfDateInputRow {
    .react-datepicker__input-container {
        width: 100%;
    }
}

.tagDocumentFooter {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.5rem $spacer;
    background-color: $white;
    border-top: 1px solid $gray-300;
}

.toggleButtonGroup {
    border: 1px solid $gray-500;
    border-left: transparent !important;
    border-right: transparent !important;
    border-radius: $border-radius-lg;
    .btn-primary {
        border-color: $white $gray-500 $white $gray-500;
        background-color: $white;
        box-shadow: none;
        font-weight: $font-weight-medium;
        outline: none;
        &:hover,
        &:focus,
        &.active {
            color: $white !important;
            background-color: $primary !important;
            border-color: $primary $gray-500 $primary $gray-500 !important;
        }
    }
    label {
        font-weight: $font-weight-bold;
    }
}

.react-datepicker-popper {
    width: 222px;
}
.react-datepicker__month {
    margin: 0.4rem 0.2rem;
    .react-datepicker__month-text {
        width: 2.5rem;
    }
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover {
    border-radius: $border-radius-md;
    color: $primary-btn-color;
}
.react-datepicker__day--selected:hover {
    color: $white;
}

.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range {
    border-radius: $rounded-pill;
    background-color: $primary;
    color: $white !important;
}

.react-datepicker__day--selected, 
.react-datepicker__day--in-selecting-range, 
.react-datepicker__day--in-range, 
.react-datepicker__month-text--selected, 
.react-datepicker__month-text--in-selecting-range, 
.react-datepicker__month-text--in-range {
    border-radius: $rounded-pill;
    background-color: $primary;
    color: $white;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
    border-radius: $rounded-pill;
    background-color: $light-blue;
    color: $primary-btn-color;
}

.react-datepicker-popper[data-placement^="top"] {
    margin-bottom: 5px !important;
}