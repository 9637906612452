@import "../../../assets/scss/variables.scss";
 
 .innerColumn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    span {
        font-weight: 500;
        margin-right: 12px; 
    }
    > div{
        width: 170px;
    }
}

.chartWrapper {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
}

.chartContainer {
    display: flex;
    flex-direction: column;
}

.downloadBtnContainer {
    display: flex;
    justify-content: flex-end;
    margin: 5px 30px;
}

.downloadBar {
    margin: 5px 13px;
}

.downloadBarStack {
    margin: 5px 13px;
}

.downloadPie {
    margin: 5px 25px;
}

.downloadImageBtn {
    font-size: 10px;
    justify-self: flex-end;
    background-color: $primary;
    color: $white;
    padding: 3px 5px;
    border: 0;
}
