@import "variables.scss";

// basic tabless
.table {
    width: 100%;
    margin-bottom: $spacer;
    color: $table-color;
    background-color: $table-bg;

    th,
    td {
        font-size: $font-size-default;
        padding: $table-cell-padding;
        border-top: none;
    }

    thead th {
        font-weight: $font-weight-medium;
        vertical-align: top;
        background-color: $table-head-bg;
        border-bottom: (1 * $table-border-width) solid $table-border-color;
    }

    tbody td {
        vertical-align: middle;
        border-bottom: (1 * $table-border-width) solid $table-border-color;
        outline: none;
        box-shadow: none;
    }

    tbody + tbody {
        border-top: (1 * $table-border-width) solid $table-border-color;
    }

    // hover
    tbody tr {
        &:hover {
            cursor: pointer;
            color: $table-hover-color;
            background-color: $table-hover-bg;
        }
    }
}

.table-container {
    border: 1px solid $gray-400;
    margin: 0rem $spacer;
    border-radius: $border-radius-xlg;
    height: calc(100vh - 200px);
    box-shadow: none;
}

.react-bs-table {
    .react-bs-container-header > table > thead > tr > th input.filter {
        font-size: $font-size-msmall;
    }
    .react-bs-container-header {
        .sort-column {
            background-color: $table-head-bg;
        }
    }
}

.gridview-wrapper {
    padding: 1.2rem 0rem 0rem 0.5rem;
}

.table-pagination-position-static {
    .react-bs-table-pagination {
        position: static;
    }
}

.react-bs-table-pagination {
    position: fixed;
    top: 81px;
    right: 0.8rem;
    z-index: 999;
}
.react-bs-table-sizePerPage-dropdown {
    .btn-secondary {
        color: $gray-900 !important;
        padding: 0.2rem 0.5rem !important;
        background-color: $white !important;
        &:active:focus {
            outline: none !important;
            box-shadow: none !important;
        }
    }
    .dropdown-toggle {
        border: 1px solid $gray-400;
        min-width: 62px;
        &:focus {
            outline: none !important;
            box-shadow: none !important;
            border-color: $gray-400 !important;
        }
    }
    .dropdown-menu {
        top: 28px !important;
        left: 0px !important;
        min-width: 62px !important;
    }
}

// affix panel table css start here
.configure-table-thead {
    font-size: $font-size-xsmall;
    font-weight: $font-weight-medium;
    position: relative;
    background-color: $table-head-bg;
    border-bottom: (1 * $table-border-width) solid $table-border-color;
    border-top-left-radius: $border-radius-lg;
    border-top-right-radius: $border-radius-lg;
}

.configure-table-tfooter {
    margin: 0.7rem 0rem 0rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: $spacer / 2 $spacer;
    background-color: $white;
    border-top: (1 * $border-width) solid $gray-500;
    &.footerFixedBottom {
        position: sticky;
        width: 100%;
        bottom: 0px;
      }
}
