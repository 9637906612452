@import "../../../assets/scss/variables.scss";
.changePasswordOuter {
  font-size: $font-size-default;
  .sectionHeader {
    height: 104px;
    color: rgba(0,0,0,0.87);
    font-weight: 500;
    font-size: 24px;
    line-height: 17px;
    align-items: center;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500; 
    align-items: center;
    padding: 0 5rem;  
    background-color: #F7F7F7;
  }
  .formWrapper {
    margin: 3rem auto;
    max-width: 488px;
    background: #FFFFFF;
    border: 1px solid #F7F7F7;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    padding: 2rem 0;
    .formControlGroup {
        position: relative;
        margin: 0 0 1.5rem 0;
        padding: 0 3rem;
        label {
          position: relative;
          display: block;
          width: 100%;
          margin-bottom: 0.7rem;
          line-height: 17px;
          color: rgba(0, 0, 0, 0.87);
        }
        &:last-child {
          margin-bottom: 0;
        }
    }
    .fromControl {
      position: relative;
      width: 100%;
      input {
        height: 40px;
        padding-left: 2.3rem;
        padding-right: 2.5rem;
      } 
      &>button {
        font-size: 16px; 
      }
      button[type='submit'] {
        background: #3196F2;
      }
      button[type='reset'] {
        background: #EEEEEE;
        border: 1px solid #E6E6E6;
        box-sizing: border-box;
        color: #727272;
        &:hover {
          color: #727272;
        }
      }
    }
  }
}

