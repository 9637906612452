@import "../../../assets/scss/variables.scss";

.toolbarViewer {
  padding: 0.3rem 0.5rem;
  display: flex;
  align-items: center;
}

.pdfViewerToolbar {
  background-color: $table-head-bg;
  border-bottom: (1 * $table-border-width) solid $gray-400;
  .btnView {
    height: 30px;
  }
  .toolbarSeparator {
    vertical-align: middle;
    margin: 0px;
    height: 42px;
    border-left: (1 * $table-border-width) solid $gray-400;
  }
}

.pageNumber {
  width: 48px;
  height: 30px;
  display: inline-block;
  text-align: center;
  border-radius: $btn-border-radius;
  background-color: transparent;
  border: 1px solid $gray-600;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.pdfPage > div {
  margin: auto;
}

.pdfPage > canvas {
  margin: auto;
}
