.pageOuterSection {
  height: auto;
}

.sectionHeader {
  height: 104px;
  color: rgba(0,0,0,0.87);
  font-weight: 500;
  font-size: 24px;
  background-color:#F7F7F7;
  line-height: 17px;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500; 
  align-items: center;
  padding: 0 5rem;  
  span {
    font-size: 16px;
    color: #868686;
    margin-left: 0.5rem;
  }
}

.mainSection {
  max-height: calc(100vh - 183px);
  overflow: scroll
}