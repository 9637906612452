@import '../../../assets/scss/variables.scss';

.badgeOutlineInfo {
  color: $cyan;
  border: 1px solid;
  padding: 0.3rem 0.7rem;
  font-weight: $font-weight-medium;
  background-color: transparent;
}

.requestFrom {
  width: 500px;
  margin: 2% auto 0;
}
.result {
    // width: 500px;
  // margin: 5% auto;
}

.thankYouWrapper {
  margin: 1rem;
  img {
    width: 150px;
  }
  h5 {
    font-size: 1.5rem;
    margin-top: 1rem;
    font-weight: 600;
    color: #1c456d;
    margin-bottom: 0;
  }
}