.sectionHeader {
    margin: 0px !important;
    border-bottom: 1px solid #ababab;
    background-color: transparent;
    min-height: 40px;
    margin-bottom: 0.3rem;
}
.panelHeader {
    h3 {
        font-size: 16px;
        font-weight: 500;
        margin: 0;
    }
}