@import "../../../assets/scss/variables.scss";

.projectsViewScroll {
  overflow-y: auto;
  overflow-x: hidden;
  flex-basis: 50%;
  table {
    text-align: left;
    position: relative;
    border-collapse: collapse;
    th {
        position: sticky;
        top: 0;
        select,
        input {
            font-size: 12px;
            font-weight: 400;
            min-width: 155px;
            padding: .3rem !important;
            border: 1px solid #b0b0b0 !important;
        }
        input + div {
           div {
               padding: 0.3rem !important
           }
        }
    }
  }
}

.folderIcon {
    position: relative;
    > div {
      font-size: 0;
      height: 34px;
      width: 34px;
      justify-content: center;
      align-items: center;
      position: relative;
      display: flex;
      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        bottom: 0px;
        left: 0px;
        right: 0px;
        top: 0px;
        border-radius: $rounded-pill;
        background-color: $gray-200;
      }
    }
  }

.propertyName {
  width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
}


.documentFileDragdrop {
  padding: $spacer * 2;
  .documentFileIcon {
    img {
      width: 60px;
    }
  }
  h1 {
    font-size: $h2-font-size;
    font-weight: $font-weight-light;
    padding: $spacer 0rem $spacer / 2;
    margin-bottom: 0rem !important;
  }
  p {
    font-size: $font-size-base;
    margin-bottom: $spacer / 3 !important;
    strong {
      font-weight: $font-weight-medium;
    }
  }
  small {
    font-size: $font-size-xsmall;
  }
}

.emptyProject {
  padding: 3rem 0;
  width: 40%;
  background: #fff;
  border-radius: 7px;
  margin: 0 auto;
  border: 1px solid #dee2e6;
  .documentFileIcon {
    svg {
      color: #dedcdc;
      font-size: 118px;
    }
  }
  h1 {
    font-size: $h2-font-size;
    font-weight: $font-weight-light;
    padding: $spacer 0rem $spacer / 2;
    margin-bottom: 3rem !important;
  }
  p {
    font-size: $font-size-base;
    margin-bottom: $spacer / 3 !important;
    strong {
      font-weight: $font-weight-medium;
    }
  }
  small {
    font-size: $font-size-xsmall;
  }
}

p.title {
  font-weight: bold;
  font-size: 2rem;
  color: #1b2c4eeb
}

.icon {
  font-size: 3rem;
  color: #2196f2
}

.spinner {
  color: #2196f2;
  height: 3rem;
  width: 3rem;
}