@import "../../../assets/scss/variables.scss";

.footer {
  padding: $spacer / 5 $spacer;
}

.trialFooter {
  background: #036fc3;
  &>div {
    max-width: 1180px;
    margin: 0 auto;
  }
  @media (max-width: 767px) { 
    padding: 0;
    &>div {
      width: 94%;
      line-height: 1.5rem;
      &>div {
        padding: 0;
        width: 50%;
      }
      &>div:last-child {
        text-align: right;
      }
    }
  }
}

.footerRow {
  justify-content: space-between;
  @media (max-width: 767px) {
    font-size: 0.6rem;
  }
}