.modal-md {
  max-width: 720px;
}

.projectInfoModalForm {
  clear: both;
  padding-top: 0.5rem;
  position: relative;
  .nav-tabs {
    display: block;
    .nav-link {
      border: none;
      color: #717b84;
      padding: 0.5rem;
      margin-right: 2px;
      position: relative;
      display: table-cell;
      text-align: center;
      width: 1%;
      &:last-child {
        border: none;
        margin-right: 0rem;
      }
      &.active {
        color: #145f97;;
        border-color: #ffffff;
        transition: all 0.5s;
        background-color: #e9f4fd;
        border-bottom: 2px solid #145f97;
        &:before {
          content: "";
          position: absolute;
          z-index: 1;
          left: 47%;
          bottom: -7px;
          border-bottom: 6px solid #145f97;
          border-right: 6px solid transparent;
          border-left: 6px solid transparent;
          transform: rotate(180deg);
          transition: all 0.5s;
          
        }
        &.error {
          color: #dc3545;
          border-bottom: 2px solid #dc3545;
          &:before {
            border-bottom: 6px solid #dc3545;
          }
        }
      }
      &:hover,
      &:focus {
        color: #145f97;
        border-color: #ffffff;
        border-bottom: 2px solid #145f97;
      }
    }
    .nav-item {
      margin-bottom: 0px;
      &.show .nav-link {
        color: #145f97;
        border-color: #ffffff;
        border-bottom: 2px solid #145f97;
      }
      &.error {
        color: #dc3545;
      }
    }
  }
  .tab-content {
    max-height: calc(100vh - 384px);
    overflow-y: auto;
  }
  .buttonToolbar {
    text-align: right;
    margin-top: 0.5rem;
    > :not(:last-child) {
      margin-right: 0.25rem;
    }
  }
  .form-control {
    height: calc(1.9em + 0.75rem + 2px);
  }
  .plusIcon {
    font-size: 24px;
    color: #495057;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sub-tabs {
    clear: both;
    width: 100%;
    padding: 0px;
    .nav-tabs {
      background-color: #f7f8f9;
      margin-bottom: 1.5rem;
      .nav-link {
        border-bottom: none;
        border-top-left-radius: 0rem;
        border-top-right-radius: 0rem;
        margin-right: 1px;
        &:first-child {
          border-left: none;
        }
        &.active {
          border-color: transparent;
          border-bottom: none;
          background-color: rgba(0, 0, 0, 0.04);
          &:before {
            display: none;
            visibility: hidden;
          }
        }
        &:hover,
        &:focus {
          border-color: transparent;
          border-bottom: none;
          background-color: rgba(0, 0, 0, 0.04);
        }
      }
      .nav-item {
        margin-bottom: 0px;
        &.show .nav-link {
          border-color: transparent;
          border-bottom: none;
          background-color: rgba(0, 0, 0, 0.04);
        }
      }
    }
    > .tab-content {
      overflow: hidden;
    }
  }
}
