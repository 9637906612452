@import "../../../assets/scss/variables.scss";

.uploadDocumentFileDrop {
    margin: $spacer;
    margin-top: 0rem !important;
    outline: none;
    box-shadow: none;
}
.dropzone-file-uploads-container {
    border: $border-width solid $border-color;
    border-radius: $border-radius-xlg;
    height: calc(100vh - 205px);
    overflow: hidden;
    box-shadow: none;
    &:focus,
    &:hover {
        outline-color: $primary;
        outline-style: $border-radius-xlg;
    }
}
