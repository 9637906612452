.sectionHeader {
    height: 104px;
    color: rgba(0,0,0,0.87);
    font-weight: 500;
    font-size: 24px;
    line-height: 17px;
    align-items: center;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500; 
    align-items: center;
    padding: 0 5rem;  
}
.videoWrapper {
    height: calc(100vh - 310px);
    padding: 4rem;
    width: 77%;
    align-items: center;
    justify-content: center;
    iframe {
        margin: 0 auto;
        width: 90%;
    }
}