.extractionOverlay {
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 111;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(4px);
  }
  .extractionAffixResizer {
    cursor: col-resize;
    border: none !important;
    opacity: 1;
    background: #ede7e7;
    width: 7px !important;
    margin: 0 !important;
    background-clip: border-box;
    box-sizing: content-box;
    z-index: 98;
    position: relative;
    &:before {
      content: "";
      border-left: 1px solid #333;
      position: absolute;
      top: 50%;
      transform: translateY(-100%);
      right: 0;
      display: inline-block;
      height: 20px;
      margin: 0 2px;
      left: -1px;
    }
    &:after {
      content: "";
      border-left: 1px solid #333;
      position: absolute;
      top: 50%;
      transform: translateY(-100%);
      right: -1px;
      display: inline-block;
      height: 20px;
      margin: 0 2px;
    }
  }
  .extractionSplitPane {
      height: auto !important;
  }