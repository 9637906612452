
.thankYouWrapper {
  margin: 0.6rem;
  img {
    width: 150px;
  }
  h5 {
    font-size: 1.5rem;
    margin-top: 1rem;
    font-weight: 600;
    color: #1c456d;
    margin-bottom: 0;
  }
  div {
    cursor: pointer;
  }
}
.activePaymentOption {
  border-color: #2096f2;
}

.price {
  // font-size: 14px;
  width: 50%;
}

.cardDetailWrapper {
  display: block;
  &>div {
    padding: 1.2rem 0;
  }
}

.discount {
  font-size: 12px;
  position: relative;
  margin-top: 8px;
  margin-left: 8px;
  color: green;
}
