@import "variables.scss";

button,
.btn,
.hs-button {
  font-size: $btn-font-size;
  border-radius: $btn-border-radius;
  padding: $btn-padding-y $btn-padding-x;
  &:focus {
    outline: none;
    box-shadow: none !important;
  }
}
.btn-primary:disabled,
.btn-primary.disabled,
.btn-primary,
.hs-button {
  border-color: $primary;
  background-color: $primary;
}

.btn-outline-primary {
  color: $primary;
  border-color: $primary;
  background-color: $white;
}

.btn-primary,
.btn-outline-primary {
  &:hover,
  &:active {
    background-color: $link-hover-color !important;
    border-color: $link-hover-color !important;
    cursor: pointer;
  }
}

.btn-block {
  display: block;
}

.btn-xs,
.dropdown-btn-xs .btn {
  padding: 3px 8px;
  font-size: small;
}

.btn-xxs {
  padding: 1px 6px;
  font-size: small;
}

.btn-circle {
  width: 30px;
  height: 30px;
  border-radius: $rounded-pill;
  text-align: center;
}

.btn-shadow {
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.18);
}

.smallIconButton {
  width: 30px;
  height: 30px;
}

.button-aligned {
  position: relative;
  button {
    padding: 4px 10px 5px !important;
  }
}

.smallIconButtonHeight {
  height: 30px;
}

.hs-button {
  color: $white;
  border: none;
  &:hover {
    background-color: $blue-hover;
    cursor: pointer;
  }
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: $primary;
  border-color: $primary;
}
