.leaseTypeHeader {
  font-size: 13px;
  font-weight: 600;
  margin: 0;
  background-color: #e5eeff;
  border-bottom: 1px solid #c0d7ea;
  position: relative;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  div {
    padding: 0.6rem 0.3rem;
    white-space: nowrap;
  }
  .serialNumber {
    width: 5%;
  }
  .leaseType {
    width: 45%;
  }
}

.leaseTypeConfig {
  overflow: auto;
  height: calc(100vh - 250px);
  width: 97.4%;
  padding-right: 0px;
  flex-grow: 0;
  flex-basis: auto;
  padding-bottom: 100px;
  .table {
    margin-bottom: 0px;
  }
  ul {
    margin-bottom: 0px;
    width: 100%;
    padding: 0.4em 0.3rem;
    display: block;
    border-bottom: 1px solid #dee2e6;
    position: relative;
    li {
      padding: 0;
      font-size: 13px;
      vertical-align: middle;
      border-top: none;
      background-color: #ffffff;
      display: inline-flex;
      width: auto;
    }
    .serialNumber {
      width: 5%;
    }
    .leaseType {
      width: 46%;
    }
    .category {
      width: 200px;
      > div {
        width: 200px;
      }
    }
  }
}

.leaseTypeFooter {
  margin: 0.7rem 0 0;
  position: relative;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
  background-color: #ffffff;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
  > :not(:last-child) {
    margin-right: 0.55rem;
  }
}

.footerFixedBottom {
  position: sticky;
  width: 100%;
  bottom: 0px;
}