.occupancyHeader {
  font-size: 13px;
  font-weight: 600;
  margin: 0;
  background-color: #e5eeff;
  border-bottom: 1px solid #c0d7ea;
  position: relative;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  div {
    padding: 0.3rem 0.4rem;
    white-space: nowrap;
  }
  .documentStatusHeader {
    width: 30%;
  }
  .unitHeader {
    width: 20%;
  }
  .sqFtHeader {
    width: 20%;
    small {
      display: block;
      color: #007bfe;
    }
  }
  .percentHeader {
    width: 130px;
  }
  .categoryHeader {
    width: 30%;
  }
}

.occupancyWrapper {
  width: 98%;
  height: calc(100vh - 286px);
  min-height: 100px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 0px;
  flex-grow: 0;
  flex-basis: auto;
  .table {
    margin-bottom: 0px;
  }
  table {
    margin-bottom: 0px;
    td {
      padding: 0.3rem 0.4em;
      font-size: 13px;
      width: auto;
      vertical-align: middle;
      border-top: none;
      background-color: #ffffff;
      border-bottom: 1px solid #dee2e6;
    }
    .documentStatus {
      width: 31%;
    }
    .units {
      width: 20%;
    }
    .sqFt {
      width: 20%;
      small {
        display: block;
        color: #007bfe;
      }
    }
    .percent {
      width: 133px;
    }
    .categroy {
      width: 115px;
    }
    .columnNowrapCode {
      width: 133px;
    }
    .columnNowrapUnits {
      width: 69px;
    }
    .columnNowrapAvgsqft {
      width: 99px;
    }
  }
}

.occupancyStatusChart {
  font-size: 13px;
  padding: 1rem 0 4rem;
}

.occupancyRRWrapper {
  height: calc(100vh - 268px);
  width: 99.8%;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 0.3rem;
}

.cardsFixBottom {
  position: relative;
}

.footerFixedBottom {
  margin: 0.7rem 0 0;
  bottom: 0px;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
  background-color: #ffffff;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
  > :not(:last-child) {
    margin-right: 0.55rem;
  }
}

.footerFixedBottom {
  position: sticky;
  width: 100%;
  bottom: 0px;
}