
@import "../../../assets/scss/variables.scss";

.uploadStatusDialog {
  position: absolute;
  right: $spacer;
  bottom: 2.1rem;
  width: 340px;
  box-shadow: $box-shadow-card;
}

.uploadProgressPopup {
  .modalHeader {
    padding: $modal-header-padding-y;
    background-color: $modal-header-bg !important;
    border-bottom: none !important;
  }
  .modalTitle {
    font-size: $font-size-lsmall;
    padding-left: 0rem;
    padding-right: 0.5rem;
  }
  .modalClose {
    padding-right: 0rem;
    padding-left: 0.5rem;
    position: relative;
    top: -3px;
  }
  .close {
    text-shadow: none !important;
  }
  .modalButtonClose {
    svg {
      position: relative;
      top: 3px;
      left: -1px;
      color: $close-color;
    }
  }
  .arrowDownIcon {
    img,
    svg {
      width: 14px;
      position: relative;
      left: -2px;
      transform: rotate(0deg);
    }
  }
  .arrowUpIcon {
    img,
    svg {
      width: 14px;
      position: relative;
      top: -2px;
      left: -2px;
      transform: rotate(180deg);
    }
  }
  .listGroupItem {
    padding: 0.3rem 0.5rem 0.3rem 0.3rem;
    &:first-child {
      border-top-left-radius: 0rem;
      border-top-right-radius: 0rem;
      border-top: none;
    }
    &:focus,
    &:hover,
    &:active {
      background-color: $list-group-hover-bg;
    }
  }
  .badge {
    font-weight: $font-weight-medium;
    border-radius: $badge-pill-border-radius;
    padding: $badge-padding-y $badge-padding-x;
  }
}

.uploadListGroup {
  max-height: 360px;
  overflow-y: auto;
  background-color: $white;
  border-bottom-right-radius: $list-group-border-radius;
  border-bottom-left-radius: $list-group-border-radius;
}

.uploadFileName {
  max-width: 200px;
}
