@import "../../../assets/scss/variables.scss";

.leaseWrapper {
    width: 97%;
    flex-grow: 0;
    flex-basis: auto;
    margin: $spacer / 2;
    background-color: $white;
    border-radius: $border-radius-lg;
    border: (1 * $border-width) solid $border-color;
}

.analysisWrapper {
    overflow: auto;
    height: calc(100vh - 408px);
}

.affixLeaseChartHeader {
    padding: $spacer / 2;
    border-bottom: (1 * $border-width) solid $border-color;
    > div > div > div,
    .sectionHeader,
    .row {
        border-bottom: none !important;
    }
}
