
.userProfile {
  .sectionHeader {
    height: 104px;
    color: rgba(0,0,0,0.87);
    font-weight: 500;
    font-size: 24px;
    line-height: 17px;
    align-items: center;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500; 
    align-items: center;
    padding: 0 5rem;  
    background-color: #F7F7F7;
  }
  .sectionWrapper {
    padding: 3rem 5rem;
  }
  .imageWrapper {
    width: 200px;
    height: 200px;
    overflow: hidden;
    background-color: #F7F7F7;
    img {
      border-radius: 10px;
      width: 100%;
      border: 1px solid #F7F7F7;
    }
  }
  .contentWrapper {
    width: 302px;
    background: #F7F7F7;
    border-radius: 10px;
    padding: 1rem;
    strong {
      display: block;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.54);
      letter-spacing: 1px;
    }
    span {
      font-weight: 300;
      color: rgba(0, 0, 0, 0.87);
      font-size: 16px;
      line-height: 17px;
    }
  }
}
