.sectiontitle {
  background-position: center;
  font-family: monospace;
  margin: 30px 0 0px;
  text-align: center;
  min-height: 20px;

  h2 {
    font-size: 30px;
    color: #222;
    margin-bottom: 0px;
    padding-right: 10px;
    padding-left: 10px;
  }
}

.headerLine {
  width: 160px;
  height: 2px;
  display: inline-block;
  background: #101F2E;
}

.sectionClass {
  padding: 20px 0px 50px 0px;
  position: relative;
  display: block;
}

.projectFactsWrap{
    display: flex;
  margin-top: 30px;
  flex-direction: row;
  flex-wrap: wrap;
}


.fullWidth {
  width: 100% !important;
  display: table;
  float: none;
  padding: 0;
  min-height: 1px;
  height: 100%;
  position: relative;
}

.item{
  width: 25%;
  height: 100%;
  padding: 50px 0px;
  text-align: center;
  cursor: pointer;

  &:hover {
    i, p, span{
      color: white
    }
  }
}


.p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  margin: 0;
  padding: 10px;
  font-family: monospace;
}

.span {
  width: 60px;
  background: rgba(255, 255, 255, 0.8);
  height: 2px;
  display: block;
  margin: 0 auto;
}
.number {
  font-size: 40px;
  padding: 0;
  font-weight: bold;
}

.number {
  font-size: 40px;
  padding: 0;
  font-weight: bold;
}

.i {
  vertical-align: middle;
  font-size: 50px;
  color: rgba(255, 255, 255, 0.8);
}
