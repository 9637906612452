@import "../../../assets/scss/variables.scss";

.floorPlanHeader {
  .floorPlan {
    font-size: 13px;
  }
}

.floorPlan {
  overflow: auto;
  width: 100%;
  padding-right: 0px;
  border-collapse: collapse;
  overflow-x: scroll;
  height: calc(100vh - 340px);
  table {
    thead{
      width: 100%;
      th {
        font-size: 13px;
        padding: 0.4em 0.2rem;
        vertical-align: top;
        position: relative;
        height: 75px;
        position: sticky;
        top: 0;
        z-index: 1;
        small {
          margin-top: 19px;
          display: block;
          color: $primary;
          position: absolute;
          bottom: 10px;
        }
      }
    }
    tbody {
      width: 100%;
      tr {
        width: 100%;
        td {
          padding: 0.4em 0.2rem;
          min-width: 100px;
          font-size: $font-size-xsmall;
          min-width: 100px;
          line-height: 16px;
          vertical-align: middle;
          border-top: none;
          background-color: $white;
          border-bottom: (1 * $table-border-width) solid $table-border-color;
          height: auto;
          small {
            color: $primary;
            display: block;
          }
        }
      }
    }
  }
  .fPlan {
    small {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.overflowPadding {
  padding-right: 30px;
}

.floorPlanFooter {
  > :not(:last-child) {
    margin-right: 0.55rem;
  }
}
.pt8 {
  padding-top: 8px;
}

.floorPlanChartWrapper {
  overflow: hidden;
  overflow-y: scroll;
  height: calc(100vh - 313px);
  width: 100%;
  margin: 0 auto;
  padding-right: 0px;
  flex-grow: 0;
  padding: 1rem 0 0 1rem;
  flex-basis: auto;
  font-size: $font-size-msmall;
  svg {
    font-size: $font-size-msmall;
  }
}

.floorPlanChartWrapperBx {
  height: 400px;
  margin: 1.5rem 0 0 0;
}

.textField::-webkit-outer-spin-button, 
.textField::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}
