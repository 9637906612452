.tileTooltip {
    font-size: 14px !important;
    letter-spacing: 0.2px;
    display: flex;
    align-items: flex-start;
    background-color: black;
    text-align: left;
    padding: 5px;

    ul {
        padding-left: 15px;
    }
}

.icon {
    display: inline-block;
}
