@import "../../../assets/scss/variables.scss";

.formCardBody {
  font-size: $font-size-default;
  .btnWarning {
    display: block;
    margin: auto;
    padding: 0.25rem 0.75rem;
  }
  small {
    font-size: 98%;
  }
  .formControl {
    height: calc(1.7em + 0.75rem + 2px);
    padding-left: 2.3rem;
  }
  button {
    padding: 0px;
    margin: 0px;
  }
  .btnPrimary {
    padding: 0.5rem 0.75rem;
  }
  svg {
    position: relative;
    left: 4px;
  }
}
