.downloadTable {
  width: 100%;
}

.otpField {
  width: 3rem !important;
}
.otpContainer {
  justify-content: center !important;
  > div {
    width: 25%;
    padding-right: 1rem;
    input {
      font-weight: bold;
      margin: 0;
      color: #007bff !important;
      width: 100% !important;
      font-size: 2rem;
      border-bottom: 2px solid #B0B0B0 !important;
      border-radius: 0px !important;
      border-left: none;
      border-right: none;
      border-top: none;
      &:focus {
        border-color: #007bff !important;
      }
    }
    &:last-child {
      padding-right: 0.5rem;
    }
  }
  
}

.thankYouWrapper {
  margin: 1rem;
  img {
    width: 150px;
  }
  h5 {
    font-size: 1.5rem;
    margin-top: 1rem;
    font-weight: 600;
    color: #1c456d;
    margin-bottom: 0;
  }
}
