.gridContainer {
  display: flex;
  flex-wrap: wrap;
}

.gridItem {
  width: 216px;
  height: 244px;
  margin: 20px;
  border: none;
  box-sizing: border-box;

  &:hover {
    background: #FFFFFF;
    mix-blend-mode: normal;
    box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.02), 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
  }

}

.card {
  padding: 0.5rem;
}

.bodyWrapper {
  margin-top: 4px;
}

.title {
  font-family: Helvetica Neue;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.04em;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
}

.subtitle {
  font-family: Helvetica Neue;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-align: left;
  color: rgba(0, 0, 0, 0.54);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.body {
  margin-top: 0.75rem;
}

.duration {
  font-size: 65% !important;
}

