@import "../../../assets/scss/variables.scss";

.abcRioButton {
    width: 100% !important;
}
.googleLoginButton {
    width: 100%;
}
.googleLoginButton > div {
    width: 50% !important;
    @media (max-width: 768px) {
        width: 75% !important;
    }
}
.googleLoginButton > div:first-child {
    margin-right: 1rem;
    @media (max-width: 768px) {
        margin-right: 0;
    }
}
.abcRioButton,
.abcRioButtonBlue .abcRioButtonIcon {
    border-radius: $btn-border-radius !important;
}
.g-signin2 {
    background: #FFFFFF;
    border: 1px solid #2196F2;
    box-sizing: border-box;
    border-radius: 4px;
}
.g-signin2 .abcRioButton {
    box-shadow: none;
    color: #2196F2;
    opacity: 0.87;
}
.g-signin2 .abcRioButton .abcRioButtonContents {
    font-size: 17px !important;
    line-height: 25px;
    margin: 0;
}
.g-signin2 .abcRioButtonIcon {
    margin-left: 13px;
    margin-right: -34px;

    @media (max-width: 768px) {
        margin-left: 0px;
    }
}
  