.spreadsheetIcon {
  direction: ltr;
  text-align: left;
  overflow: hidden;
  vertical-align: middle;
  height: 18px;
  width: 18px;
  margin: 5px;
}
.spreadsheetSpriteIcon {
  position: absolute;
}
.spreadsheetSpriteImg {
  content: url(../../../assets/images/clikai-icons-sprite-grey.svg);
  &:before {
    content: url(../../../assets/images/clikai-icons-sprite-grey.svg);
  }
}
// button icon
.spreadsheetToolbarButton {
  box-shadow: none;
  outline: none;
  background-color: #ffffff;
  background-image: none;
  cursor: pointer;
  border-radius: 3px;
  position: relative;
  top: 0px;
  color: rgba(0, 0, 0, 0.7);
  margin: 0 2px;
  &:hover {
    background-color: #f1f3f5;
  }
  .spreadsheetIcon {
    opacity: 1;
  }
}
.spreadsheetButtonIcon {
  font-size: 14px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.7) !important;
  background-color: #ffffff;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
  &:hover,
  &:focus,
  &:active {
    background-color: #f1f3f5 !important;
    border-color: #ababab !important;
    border: none;
    box-shadow: none !important;
    outline: none !important;
  }
}
.spreadsheetButtonSize {
  direction: ltr;
  text-align: left;
  overflow: hidden;
  vertical-align: middle;
  height: 28px;
  width: 28px;
  margin: 1px 2px;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  top: 0px;
  border: 1px solid #ababab;
}
