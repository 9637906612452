.buttonAlignment {
    width: 30px;
    height: 30px;
  }
  
  .iconInner {
    border-radius: 50% !important;
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.18);
  }
  
  .projectsSubHeader {
      position: relative;
      h5 {
        margin-bottom: 0px;
      }
      &:after {
        width: 14px;
        position: absolute;
        left: 0px;
        top: 100%;
        height: 2px;
        content: "";
        background-color: #ffffff;
      }
    
      &:before {
        width: 14px;
        position: absolute;
        right: 0px;
        top: 100%;
        height: 2px;
        content: "";
        background-color: #ffffff;
      }
    }
  
  .headingAligment {
    position: relative;
    top: -2px;
  }