@import "variables";

.form-control {
  font-size: $input-font-size;
  border-color: $input-border-color !important;
  border-radius: $input-border-radius-lg !important;
  font-weight: $input-font-weight;
}

.form-group {
  label {
    font-size: $font-size-default;
    font-weight: $font-weight-normal;
    margin-bottom: 0rem;
  }
}

.form-control-sm {
  padding: $input-padding-y-sm $input-padding-x-sm;
  font-size: $font-size-default;
  line-height: $input-line-height-sm;
  border-radius: $input-border-radius-sm;
}

.form-control-xs {
  font-size: $font-size-default;
  padding: 0.1rem 0.5rem;
}

.search-icon {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 1px;
  left: 2px;
  svg {
    color: $gray-700;
  }
}

.search-bar-inputField {
  padding: 0rem 0.7rem 0.1rem 2rem;
}


.form-card {
  box-shadow: $box-shadow-card;
  width: 350px;
  margin: auto;
  border: none;
}

.form-card-body {
  padding: $card-body-spacer-x;
  p,
  small {
    font-size: $input-font-size;
  }
}

.form-control,
.css-yk16xz-control,
.css-1pahdxg-control,
.css-1uccc91-singleValue,
.css-b8ldur-Input,
.css-1wa3eu0-placeholder {
  color: $input-color !important;
  &:focus {
    color: $input-focus-color;
    border-color: $input-border-color !important;
    outline: none;
    box-shadow: none;
    background-color: $white;
  }
  &:hover {
    cursor: text;
    border-color: $input-border-color;
  }
}

// custom form css start here
.css-1uccc91-singleValue,
.css-b8ldur-Input,
.css-1wa3eu0-placeholder {
  color: $input-color !important;
}

.css-yk16xz-control,
.css-1pahdxg-control {
  font-size: $input-font-size;
  border-color: $input-border-color !important;
  border-radius: $input-border-radius-lg !important;
  font-weight: $input-font-weight;
  min-height: 30px !important;
  outline: none;
  box-shadow: none !important;
}

.css-26l3qy-menu {
  font-size: $input-font-size !important;
  border-radius: $input-border-radius-lg !important;
}

.css-4ljt47-MenuList,
.css-yt9ioa-option,
.css-1n7v3ny-option,
.css-9gakcf-option {
  font-size: $input-font-size !important;
}

.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  padding: 3px !important;
}

.chargeCodeSelect__option {
  min-height: 35px;
}

.react-select.react-select__disabled{
  pointer-events: none;
  background: $gray-400
}

.form-control,
.css-yk16xz-control,
.css-1pahdxg-control,
.css-1uccc91-singleValue,
.css-b8ldur-Input,
.css-1wa3eu0-placeholder {
  &:focus {
    color: $gray-700;
    border-color: $gray-500 !important;
    outline: none;
    box-shadow: none;
    background-color: $white;
  }
  &:hover {
    cursor: text;
    border-color: $gray-500;
  }
}

// custom form css start here
.css-1uccc91-singleValue,
.css-b8ldur-Input,
.css-1wa3eu0-placeholder {
  color: $input-color !important;
}

.css-yk16xz-control,
.css-1pahdxg-control {
  font-size: $input-font-size;
  border-color: $input-border-color !important;
  border-radius: $input-border-radius-lg !important;
  font-weight: $input-font-weight;
  min-height: 30px !important;
  outline: none;
  box-shadow: none !important;
}

.css-26l3qy-menu {
  font-size: $input-font-size !important;
  border-radius: $input-border-radius-lg !important;
}

.css-4ljt47-MenuList,
.css-yt9ioa-option,
.css-1n7v3ny-option,
.css-9gakcf-option {
  font-size: $input-font-size !important;
}

.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  padding: 3px !important;
}

.chargeCodeSelect__option {
  min-height: 35px;
}
.payment-form-card {
  width: 550px;
}

// input group content center end
.input-group-content-center-end {
  .form-control {
    padding-right: $spacer * 4.5 !important;
  }
  .input-group {
    > .form-control {
      width: 100%;
      flex: none;
    }
  }
  .input-group-append {
    width: auto;
    position: absolute;
    z-index: 5;
    top: 0rem;
    right: 0rem;
    .input-group-text {
      border-color: $input-border-color !important;
    }
  }
  
}
