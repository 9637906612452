@import "../../../assets/scss/variables.scss";

.largeTile {
  width: 162px;
  &.bgInfo {
    background-color: $teal !important;
    border: 1px solid #99d1ff;
    color: $primary;
  }

  &:hover,
  &:focus {
    background-color: #e5f3ff;
  }
  .folderIcon {
    height: 44px;
    img {
      width: 44px;
      margin: auto;
    }
  }
  small {
    display: flex;
  }
}
.badgeInfo {
  top: 7px !important;
  left: -7px !important;
  height: 15px;
  line-height: 9px;
  font-size: 65%;
  border-radius: 0;
  &:before {
    content: "";
    position: absolute;
    display: block;
    border-left: 0px solid transparent;
    border-right: 5px solid transparent;
    border-top: 15px solid $cyan;
    top: 0;
    left: 100%;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 0;
    top: 100%;
    left: 0px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 6px 0;
    border-color: transparent #1d456d transparent transparent;
  }
}
.statusComplete {
  position: absolute;
  right: 5px !important;
  top: 1px !important;
}
