.appContextMenu > div > {
  :nth-child(2) {
    right: 252px !important;
    left: auto !important;
    margin-top: 10px;
  }
}

.widthDotted {
  width: 18px;
  height: 34px;
  line-height: 34px;
  text-align: center;
}
