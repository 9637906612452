@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/variables';
@import 'variables';

.dotted-bg {
  background: url(../images/login-background.svg);
}

.cursor-pointer {
  cursor: pointer;
}

.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  height: 100vh;
  width: 100%;
  margin: auto;
  overflow: hidden;
}

.vertical-section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: stretch;
}

.horizontal-section {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: stretch;
}

.page-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: auto;
  width: 100%;
  overflow: hidden;
}

.text-with-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.valign-middle {
  vertical-align: -webkit-baseline-middle;
  vertical-align: -moz-middle-with-baseline;
}

.file-drop-zone {
  background: $light;
}

.no-selection-highlight::selection {
  background: none;
}

a.link-light {
  color: $light;

  &visited {
    color: $light;
  }

  &hover {
    color: $light;
  }
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.fill-parent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.no-user-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.z-index-10 {
  z-index: 10;
}

.word-wrap-normal {
  word-wrap: break-spaces;
}

.max-width-400 {
  max-width: 400px;
}

.overflow-scroll {
  overflow: scroll;
}

.fw-200 {
  width: 200px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: $rounded-pill;
}

::-webkit-scrollbar-thumb {
  background-color: $gray-400;
  box-shadow: inset 0 0 5px $gray-500;
  border-radius: $rounded-pill;

  &:hover {
    background: $gray-500;
  }
}

.light-blue {
  background-color: $gray-100;
}

.border-bottom-light-blue {
  border-bottom: 1px solid $border-color !important;
}

a:hover {
  color: $primary;
  text-decoration: $link-decoration;
}

.clikai-title {
  font-size: $h3-font-size;
  font-weight: $font-weight-light;
}

.clikai-subHeading {
  font-size: $h4-font-size;
  margin-bottom: $spacer;
  font-weight: $font-weight-normal;
}

.clikai-subtitle {
  font-size: $h5-font-size;
  margin-bottom: $spacer;
  font-weight: $font-weight-normal;
}

.tourIconBtn {
  .mr-1 {
    margin-right: 0 !important;
  }
}

.reactour__helper {
  padding: $spacer;
  h6 {
    font-size: $font-size-lg !important;
    padding-bottom: 0.7rem;
    margin-bottom: $spacer;
  }
  p {
    font-size: $font-size-default !important;
  }
  .dCXvlA,
  .flMjGC {
    width: 30px;
    height: 30px;
    line-height: 30px;
    padding: 0rem !important;
    top: -$spacer * 1.25 !important;
    left: -$spacer * 1.25 !important;
  }
  .btn {
    margin-top: $spacer / 2;
  }
  .eTpeTG {
    top: $spacer * 1.5;
    right: $spacer;
    width: 12px;
    height: 12px;
  }
}

.paragraph {
  line-height: 28px;
  text-align: justify;
}

.text-content li {
  margin-bottom: 10px;
  line-height: 26px;
  text-align: justify;
}
.sa-breadcrumb + h4 {
  padding: 0.5rem $spacer;
}

.sa-breadcrumb {
  padding: 0.5rem $spacer !important;
}

// customizePagination
.pagination {
  margin-bottom: 0px;
  margin-top: 2px;
  .page-item {
    &.active .page-link {
      border-radius: $border-radius !important;
      outline: none;
      box-shadow: none;
      color: $white !important;
      background-color: $link-hover-color;
    }
    .page-link {
      border: 1px solid transparent;
      margin-left: 1px !important;
      padding: 0.2rem 0.5rem !important;
      color: $gray-900;
      &:hover,
      &:focus {
        border-radius: $border-radius !important;
        outline: none;
        box-shadow: none;
        color: $link-hover-color;
      }
    }
  }
}

.small-heading {
  font-size: $font-size-base;
  font-weight: $font-weight-medium;
}

.needSupport {
  position: absolute;
  right: 0.7rem;
  font-size: $font-size-small;
  font-weight: $font-weight-medium !important;
  font-style: italic;
  height: 16px;
  line-height: 10px;
}
.toast {
  z-index: 99;
  max-width: max-content;
  left: 0 !important;
  right: 0;
  margin: 0 auto !important;
}

.bg-invalid-datatype {
  background: #da3125;
}
.spread-validation-error {
  max-width: 320px;
  top: 27% !important;
  right: 8% !important;
  left: auto !important;
}
.spread-validation-error .toast-header {
  justify-content: space-between;
  background: #da3125;
  color: #fff;
  font-weight: 600;
}
.spread-validation-error .toast-header .close {
  color: #fff;
  opacity: 1;
}
.spread-validation-error .toast-body {
  color: #000 !important;
  font-size: 13px;
  text-align: left;
}
.spread-validation-error .toast-body strong {
  font-weight: 600;
}
.trialHeader img {
  width: 120px;
}
.sort-column .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.sort-column .dropup .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}
.splitter-layout {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.splitter-layout .layout-pane.layout-pane-primary {
  flex: 1 1 auto;
}
.splitter-layout .layout-pane {
  position: relative;
  flex: 0 0 auto;
  overflow: auto;
}
.apiConfigurationGenerateKeyButton {
  line-height: 0;
  height: 30px;
}
.beta-bedge {
  right: -4px;
  top: -10px !important;
}
.home-bg span {
  box-sizing: border-box;
  display: block;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0;
  margin: 0;
  padding: 0;
  max-width: 100%;
}

.home-left-bg {
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: -1;
}

.home-left-bg img {
  max-width: 1200px;
  height: 553;
}

.home-right-bg img {
  max-width: 1000px;
  height: 525;
}

.home-right-bg {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: -1;
}


.docuemntTaggingLoader {
  width: 30px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side,#979797 90%,#0000);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
}

@media (max-width: 768px) {
  .g-signin2 .abcRioButton .abcRioButtonContents {
    font-size: 14px !important;
  }      
}