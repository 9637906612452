.summaryTreeView {
  margin-bottom: 0.5rem;
  padding-right: 1px;
}
.summaryTreeView .rstcustom__rowWrapper > .rstcustom__row {
  padding-left: 10px !important;
  padding-right: 10px;
}

.rstcustom__rowWrapper {
  padding: 1px 0px !important;
}

.summaryTreeView .rstcustom__nodeContent {
  width: 100%;
  position: relative;
  height: 42px;
  line-height: 42px;
}

.ReactVirtualized__Grid {
  outline: none;
  box-shadow: none;
}

.summaryTreeView .rstcustom__collapseButton,
.summaryTreeView .rstcustom__expandButton {
  top: 0;
  position: absolute;
  width: 100%;
  padding: 0;
  transform: translate(0%, 0%);
  text-align: left;
  height: 100%;
  font-weight: 700;
}

.rstcustom__expandButton:before,
.rstcustom__collapseButton:before {
  height: 11px;
  width: 11px;
  position: absolute;
  content: "";
  left: 1.3rem;
  top: 50%;
  transform: translate(-10%, -50%);
  background-image: url(../../../assets/images/right-arrow.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.rstcustom__collapseButton:before {
  transform: rotate(90deg);
  top: 40%;
  left: 1.1rem;
}

.rstcustom__rowContents {
  box-shadow: none !important;
  outline: none;
}

.rstcustom__node.rstcustom__highlight {
  background: none !important;
}

// col resize css here
.splitter-layout {
  > .layout-splitter {
    background-image: url(../../../assets/images/col-resize.svg);
    background-repeat: no-repeat;
    background-size: 50px 50px;
    background-position: 50% 50%;
    transition: all 0.2s ease-in;
  }
}
