.form-modal-dictionary {
    min-width: 900px;
}
.modal-body .no-data-container {
    margin: 0 auto;
    text-align: center;
}

.form-modal-dictionary table {
 td, th:not(:first-child) {
    width: 18%;
 }
}

.form-modal-dictionary table {
    td, th:first-child {
        width: 5%;
    }
}