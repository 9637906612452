@import "../../../assets/scss/variables.scss";

.clikaiBanner {
    width: 90%;
    margin-top: $spacer / 2;
    img {
        width: 100%;
    }
}

.clikaiDesc {
    padding-top: $spacer;
    .clikaiDescItem {
        padding: 0rem 0.7rem;
        font-weight: $font-weight-normal;
        display: inline-block;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            display: block;
            top: 10px;
            right: -5px;
            width: 8px;
            height: 8px;
            border-radius: $rounded-pill;
            background-color: rgba($black, 0.5);
        }
        &:first-child {
            padding-left: 0rem;
        }
        &:last-child {
            padding-right: 0rem;
        }
        &:last-child {
            &:before {
                background-color: transparent;
            }
        }
    }
}

.loginButton {
    display: block;
    width: 50%;
    margin: auto;
}
