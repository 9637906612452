.analysisAffixChart {
    padding-top: 1.5rem;
    .recharts-wrapper {
        width: 460px !important;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1280px) {
    .analysisAffixChart {
      .recharts-wrapper {
          overflow: scroll;
      }
  }
}
@media only screen and (max-width: 1024px) {
    .analysisAffixChart {
        .recharts-wrapper {
            overflow: scroll;
        }
    }
}
