.relatedVideo {

  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #F7F7F7
  }
}

.icon {
  margin: 10px;
  font-size: 30px;
  color: #478CF9
}

.title {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.04em;
  color: rgba(0, 0, 0, 0.87);
}

.playingVideo {
  background-color: #F7F7F7
}

.heading {
  font-family: Helvetica Neue;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-bottom: 1rem;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.duration {
  font-size: 70% !important;
}