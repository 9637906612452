@import "../../../assets/scss/variables.scss";

.clipboardInput {
  font-size: $font-size-default;
  margin-top:1rem;
  .check{
    color: $green
  }
  .inputBorder {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
}
