.projectNotes {
    bottom: 5% !important;
    > div {
       background-color: #0069d9;
       margin-bottom: 0 !important;
       padding-bottom: 6px;
       p {
        color: #fff;
       }
    }
    button {
        top: -3px !important;
        right: -3px !important;
        margin-right: 8px;
        padding: 0.3rem !important;
        span {
          color: #fff;
          margin-top: 3px;
        }
    }
    p {
        color: #444;
        font-size:0.93rem;
        max-height: 150px;
        overflow-y: auto;
    }
}