.affixWrapper {
    z-index: 99;
    background-color: #ffffff;
    height: calc(100vh - 98px);
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    position: relative;
    border-left: 1px solid #ababab;
    border-top: 1px solid #ababab;
    margin-right: 48px;
    &:before {
        content: "";
        position: absolute;
        width: 48px;
        height: 46px;
        right: -47px;
        top: -47px;
        border-left: 1px solid #ababab;
    }
}

.affixWrapperOpen {
    width: calc(100% - 55px);
    z-index: 97;
}

.affixWrapperAcquitionOpen {
    width: calc(100% - 44px);
}

.affixNav {
    position: absolute;
    z-index: 1;
    left: -34px;
    top: 0px;
    width: 35px;
    height: 38px;
    display: flex;
    cursor: pointer;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: #404040;
    border: 1px solid transparent;
    justify-content: center;
    transition-property: all;
    transition-duration: 0.5s;
    &.iconActive {
        transform: rotate(180deg);
    }
    &:hover,
    &:focus {
        cursor: pointer;
        background-color: #404040;
        button {
            color: #fff;
        }
    }
}

.affixNav button {
    font-size: 24px;
    border: none;
    padding: 0px;
    transition: 1s ease;
    margin-right: 0px !important;
    color: #fff;
    line-height: 20px;
    &:hover {
        background-color: transparent !important;
    }
}
