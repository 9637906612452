.documentNotFoundCol {
    padding: 0 2rem;
    color: #999999;
    text-align: center;
    .fileNotFoundImage {
        clear: both;
        text-align: center;
        margin-bottom: 1rem;
        img {
            width: 150px;
            margin: auto;
        }
    }
}
