.file-badge {font-size: 14px}
.file-badge .file-name{ background: #c6e1f1; margin: 0 15px; border-radius: 4px }
.file-badge .file-name button{ float: right; text-decoration: none }
.file-input{ border: none; margin: 5px 0; padding:0; width: 0.1px; height: 0.1px; opacity: 0;
  overflow: hidden; position: absolute; z-index: -1}
.file-button{float: right; text-decoration: none }
.file-label{  border: 1px solid #224c88; padding: 5px; border-radius:4px}
.margin-bottom-zero{ margin-bottom: 0}
.info-allowed-files {color: grey; font-size: smaller;}
.file-label:hover {background: #2196f2; color: #fff}
