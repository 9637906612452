@import "../../../assets/scss/variables.scss";

.toolbarViewerSpacerRight {
  margin-right: $spacer;
}

.pdfViewerWrapper {
  border: 1px solid $gray-400;
  border-radius: $border-radius-lg;
  height: calc(100vh - 200px);
  box-shadow: none;
}
