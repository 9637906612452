@import "../../../assets/scss/variables.scss";

.shareOptionsContainer{
    display: flex;
    justify-content: flex-start;
    margin: 10px 0;
}
.shareOptionsText {
    margin-right: 5px;
    position: relative;
    top: 2.5px;
}
.shareOptionsContent {
    display: flex;
    justify-content: space-between;
}
.shareOption {
    padding: 0 2px !important;
}
  