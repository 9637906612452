.projectsHeader {
  .formControlXs {
    font-size: 0.84rem;
  }
  .btn-xs {
    padding: 5px 8px 6px;
  }
}

.headingAligment {
  position: relative;
  top: -2px;
}
