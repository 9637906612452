.slidePanel {
  position: relative;
  z-index: 90;
  .paneHeader {
    background-color: #404040;
    width: 100%;
    align-items: center;
    padding: 0.6rem 0.8rem 0.7rem;
    min-height: 48px;
    h3 {
      font-size: 18px;
      font-weight: 300;
      margin: 0;
      color: #fff;
      padding-left: 2rem;
    }
    button {
      margin-right: 0px !important;
    }
  }
}

.closeRightArrow {
  position: absolute;
  z-index: 999999;
  right: 12px;
  top: 6px;
  width: 26px;
  height: 26px;
  line-height: 22px;
  cursor: pointer;
  border-radius: 0.25rem;
  background-color: transparent;
  border: 1px solid #6c757d;
  text-align: center;
  justify-content: center;
  &:hover,
  &:focus {
    cursor: pointer;
    background-color: #5a6268;
    button {
      color: #ffffff;
    }
  }
  button {
    border: none;
    padding: 0px;
    margin-right: 0px !important;
    color: #6c757d;
  }
  svg {
    font-size: 16px;
    font-weight: 300;
    align-items: center;
    display: flex;
  }
}

.slidePaneWidth {
  width: 100%;
}

// @media only screen and (min-width: 1600px) and (max-width: 1680px) {
//   .slidePaneWidth {
//     width: 490px;
//   }
// }
// @media only screen and (max-width: 1440px) {
//   .slidePaneWidth {
//     width: 480px;
//   }
// }
// @media only screen and (min-width: 1360px) and (max-width: 1366px) {
//   .slidePaneWidth {
//     width: 470px;
//   }
// }
// @media only screen and (min-width: 1200px) and (max-width: 1280px) {
//   .slidePaneWidth {
//     width: 465px;
//   }
// }
// @media only screen and (max-width: 1024px) {
//   .slidePaneWidth {
//     width: 465px;
//   }
// }
