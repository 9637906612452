@import '../../../assets/scss/variables.scss';

.chargeCodeHeader {
  margin: 0;
  div {
    padding: 0.6rem 0.3rem;
    white-space: nowrap;
  }
  .widthId {
    width: 5%;
  }
  .widthChargeCode {
    width: 30%;
  }
  .widthTotalAmount {
    width: 29%;
  }
}

.chargeCodeList {
  .widthId {
    width: 5%;
  }
  .widthChargeCode {
    width: 30%;
  }
  .widthTotalAmount {
    width: 30%;
  }
  .widthChargeCodeOption {
    width: 30%;
    display: inline-block;
  }
}

.chargeCode {
  overflow: auto;
  height: calc(100vh - 302px);
  width: 100%;
  padding: 0.6rem 0.3rem;
  flex-grow: 0;
  flex-basis: auto;
  .table {
    margin-bottom: 0px;
  }
  ul {
    margin-bottom: 0px;
    width: 100%;
    padding: 0.4em 0.3rem;
    display: block;
    border-bottom: (1 * $table-border-width) solid $table-border-color;
    position: relative;
    li {
      padding: 0;
      font-size: $font-size-xsmall;
      vertical-align: middle;
      border-top: none;
      background-color: $white;
      display: inline-flex;
      width: auto;
    }

    .dottedIcon {
      position: relative;
      right: -10px;
      cursor: pointer;
      div {
        padding: 0;
      }
    }
    .overlayView {
      position: absolute;
      width: 100%;
      top: 0;
      background-color: $light-blue;
      height: 100%;
      padding: 0;
      left: 0;
      div {
        font-size: $font-size-xsmall !important;
      }
    }
    .overlayViewInputs {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: row;
      border: none;
      padding: 0;
      li {
        display: inline-flex;
        background: inherit;
      }
      .overlayViewOptions {
        display: inline-block !important;
        width: 36%;
        margin: 0 3px;
      }
      .overlayViewButton {
        margin: 0 4px;
        width: 36px;
        border: (1 * $table-border-width) solid $table-border-color;
        height: 33px;
      }
      .closeIcon {
        img {
          width: 12px;
        }
      }
      .textDesciption {
        text-align: center;
        justify-content: center;
        font-size: $font-size-small;
      }
    }
  }
}

.chargeCodeFooter {
  > :not(:last-child) {
    margin-right: 0.55rem;
  }
}

.appContextMenu > div > {
  :nth-child(2) {
    right: 252px !important;
    left: auto !important;
    margin-top: 10px;
  }
}

.widthChargeCodeDotted {
  width: 98%;
}

.remove-margin {
  margin-right: unset;
  margin-left: unset;
}
