.appPagination {
    display: flex;
    div {
        display: flex;
        justify-content: center;
        align-items: center;
        input{
            width: 56px;
            max-width: 100%;
            padding: 0.3rem 0.5rem;
            background-color: #f4f4f4;
            box-shadow: none;
            font-weight: 600;
            text-align: center;
            border: 1px solid #ddd;
            border-radius: 5px;
        }
        span,
        strong {
            margin: 0 10px;
        }
    }
    button {
        border: none;
        padding: 0 0.3rem;
        font-size: 1.3rem;
        &:disabled {
            cursor: not-allowed;
            opacity: 0.3;
        }
    }
}
.tableHeaderWrapper {
    position: sticky;
    top: -1rem;
    background: #fff;
    padding: 1rem 0;
}
.paginationDropdown {
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    &>div {
        width: 70px;
        margin-left: 5px;
    }
}
.linePreloader{
    width: 100%;
    height: 4px;
    background: linear-gradient(to right,#2296f2,#18a2b8);
    background-color:#ccc;
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    margin:auto;
    border-radius:4px;
    background-size:20%;
    background-repeat:repeat-y;
    background-position:-25% 0;
    animation:scroll 1.2s ease-in-out infinite;
  }
.companyTypeFilter {
    width: 20%;
    margin-left: 5px;
    div {
        color: #fff !important;
        background-color: #2196f2;
    }
}

  @keyframes scroll{
    50%{background-size:80%}
    100%{background-position:125% 0;}
  }


.noRecord {
    display: flex;
    justify-content: center;
}