.occupancyHeader {
  font-size: 13px;
  font-weight: 600;
  margin: 0;
  background-color: #e5eeff;
  border-bottom: 1px solid #c0d7ea;
  position: relative;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  div {
    padding: 0.6rem 0.3rem;
    white-space: nowrap;
  }
  .documentStatusHeader {
    width: 20%;
  }
  .sqFtHeader {
    width: 20%;
  }
  .nra {
    width: 20%;
  }
  .rent {
    width: 20%;
  }
  .recovery {
    width: 20%;
  }
}

.tenantType {
  width: 99%;
  height: calc(100vh - 345px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 0px;
  flex-grow: 0;
  flex-basis: auto;
  .table {
    margin-bottom: 0px;
  }
  table {
    margin-bottom: 0px;
    td {
      padding: 0.4rem 0.3em;
      font-size: 13px;
      width: auto;
      vertical-align: middle;
      border-top: none;
      background-color: #ffffff;
      border-bottom: 1px solid #dee2e6;
    }
    .documentStatusHeader {
      width: 20%;
    }
    .sqFtHeader {
      width: 20.2%;
    }
    .nra {
      width: 20%;
    }
    .rent {
      width: 20%;
    }
    .columnNowrapSqftHeader {
      width: 73px;
    }
    .columnNowrapNra {
      width: 73px;
    }
    .columnNowrapRent {
      width: 85px;
    }  
  }
}

.calculationPercentage {
  margin: 0.7rem 0.5rem;
  border-radius: 0.3rem;
  position: relative;
  border-top: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #ffffff;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
  padding: 0.5rem 0 1.7rem;
  p {
    padding: 0;
    margin-bottom: 1.2rem;
    font-size: 13px;
  }
}

.tenantTypeWrapper {
  height: calc(100vh - 296px);
  width: 99.8%;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 0.3rem;
  svg {
    font-size: 12px;
  }
}

.cardsFixBottom {
  margin: 0.7rem 0 0;
  position: relative;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
  background-color: #ffffff;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
  > :not(:last-child) {
    margin-right: 0.55rem;
  }
}

.footerFixedBottom {
  position: sticky;
  width: 100%;
  bottom: 0px;
}