@import "../../../assets/scss/variables.scss";

.formCardBody {
  font-size: $font-size-default;
  .btnWarning {
    display: block;
    margin: auto;
    padding: 0.25rem 0.75rem;
  }
  .linkedInButton {
    display: flex;
  }
  small {
    font-size: 98%;
  }
  .formControl {
    height: calc(1.7em + 0.75rem + 2px);
    padding-left: 2.3rem;
  }
  .formSeparator {
    display: block;
    position: relative;
    height: 30px;
    margin-top: 30px;
    small {
      height: 1px;
      background: #ddd;
      width: 100%;
      position: absolute;
    }
    span {
        background-color: #fff;
        position: absolute;
        width: 50px;
        top: -10px;
        left: 0;
        margin: 0 auto;
        right: 0;
        padding: 0 10px;
        text-align: center;
    }
  }
  button {
    padding: 0px;
    margin: 0px;
  }
  .btnPrimary {
    padding: 0.5rem 0.75rem;
  }

  svg {
    position: relative;
    left: 4px;
  }
 
  .btnEmail {
    font-size: 17px;
    padding: 0.8rem 0;
  }
}
