@import "../../../assets/scss/variables.scss";

.dropdownHeader {
  padding: 7px 10px;
  font-size: 0.875rem;
  color: $gray-600;
  white-space: nowrap;
}

.widthTextOverflow {
  width: 172px;
}

.badgePro {
  position: absolute;
  right: 0px;
  top: -8px;
  font-size: $font-size-small;
  font-weight: $font-weight-medium !important;
  font-style: italic;
  height: 14px;
  line-height: 12px;
  padding: 1px 7px 3px 7px;
  z-index: 2;
}

.deadLink {
  cursor: not-allowed;
  pointer-events: none;
}

.thankYouWrapper {
  margin: 1rem;
  img {
    width: 150px;
  }
  h5 {
    font-size: 1.5rem;
    margin-top: 1rem;
    font-weight: 600;
    color: #1c456d;
    margin-bottom: 0;
  }
}

.dropdownItem {
  width: 180px;
  white-space: nowrap !important; 
  overflow: hidden !important;
  text-overflow: ellipsis !important; 
  display: inline-block !important;
}