@import "variables.scss";

.loginFormLogo {
    margin-bottom: 1.5rem;
    img {
        width: 150px;
    }
}
.loginFormDarkLogo {
    margin-bottom: 1.5rem;
    img {
        width: 250px;
    }
}
.loginFormIcon {
    position: absolute;
    z-index: 5;
    top: 31px;
    left: 0.7rem;
    &.emailIcon {
        top: 2.1rem !important;
    }
    &.passwordIcon {
        top: 0.6125rem !important;
    }
    &.confirmLoginFormIcon {
        top: 33px !important;
    }
    &.confirmPasswordAccountSettings {
        left: 1.7rem !important;
    }
    &.confirmResetPassword {
        top: 2.1rem !important;
    }
}

.passwordGroupRow {
    .input-group > .form-control {
        width: 100%;
        flex: none;
    }
    .showPasswordIcon {
        width: 36px !important;
        border: none !important;
        padding: 0rem !important;
        position: absolute;
        z-index: 5;
        top: 0rem;
        right: 0rem;
        background-color: transparent !important;
        svg {
            color: $gray-700;
        }
    }
}
