.sort__table__title {
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  left: 0;
  top: 0;
  padding: 9px 7px 9px 24px;
  position: absolute;
  font-size: 14px;
  font-weight: normal;

  span {
    position: absolute;
    right: 0.5rem;
    top: 0;
  }
}

.summaryTtile {
  font-size: 17px;
  min-height: 43px;
  border-bottom: 1px solid #dee2e6;
  background: linear-gradient(#f5f5f5, #e5e5e5);
}
