
.videoWrapper {
  border-radius: 0.3rem;
  margin: 15px;
  cursor: pointer;
  border: solid 1px;
}

.playerCard {
  background: #FFFFFF;
  mix-blend-mode: normal;
  box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.02), 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.videoTitle {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.87);
}

.videoDescription {
  width: 488px;
  height: 83.75px;
  left: 0px;
  top: 32px;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.04em;
  color: rgba(0, 0, 0, 0.87);
}

.durationNumber {
  font-family: Helvetica Neue;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.04em;
  text-align: left;
}

.durationText {
  font-family: Helvetica Neue;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0.04em;
  text-align: left;
}