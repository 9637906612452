.documentSummaryChart {
  .recharts-default-legend {
    padding-top: 1rem !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1280px) {
  .documentSummaryChart {
      .recharts-wrapper {
          overflow: scroll;
      }
  }
}

@media only screen and (max-width: 1024px) {
  .documentSummaryChart {
      .recharts-wrapper {
          overflow: scroll;
      }
  }
}
