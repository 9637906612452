.projectsSubHeader {
  position: relative;
  h5 {
    margin-bottom: 0px;
  }
  &:after {
    width: 14px;
    position: absolute;
    left: 0px;
    top: 100%;
    height: 2px;
    content: "";
    background-color: white;
  }

  &:before {
    width: 14px;
    position: absolute;
    right: 0px;
    top: 100%;
    height: 2px;
    content: "";
    background-color: white;
  }
}

.headingAligment {
  position: relative;
  top: -2px;
}
