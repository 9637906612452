@import 'variables.scss';

.dropdown-menu {
  left: auto;
  min-width: $dropdown-min-width !important;
  border-radius: $border-radius-lg;
  box-shadow: $dropdown-box-shadow;
  border: 1px solid $gray-300;

  .dropdown-item {
    font-size: $font-size-default;
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    display: flex;
    align-items: center;
    white-space: normal;
    &:hover {
      color: $gray-900 !important;
      background-color: $light !important;
    }
    &:focus,
    &.active,
    &:active {
      color: $gray-900 !important;
      background-color: $light-blue !important;
    }
  }
}

.bg-dark {
  background-color: $dark !important;
}

.bg-light {
  background-color: $light !important;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: $font-weight-normal;
}

.text-primary {
  color: $primary !important;
}
.text-error {
  color: $text-error !important;
}

.border {
  border: $border-width solid $border-color !important;
}
// navbar expands
.navbar-expand {
  .navbar-nav {
    flex-direction: row;

    .nav-link {
      padding-right: $navbar-nav-link-padding-x;
      padding-left: $navbar-nav-link-padding-x;
    }
  }
}

.width-dottedCol {
  .dropdown-menu .dropdown-item {
    padding: $input-btn-padding-y $dropdown-item-padding-x !important;
  }
}
