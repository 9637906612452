.viewIcons {
    width: auto;
    margin-right: 44px;
    border-radius: 3px;
    display: inline-flex;
    line-height: 1;
    overflow: hidden;
    background-color: #ffffff;
    border: 1px solid #6c757d;
    button {
        margin: 0 !important;
        border: none;
        border-radius: 0px;
        height: 24px;
        border-right: 1px solid #6c757d;
        &:focus,
        &:hover {
            color: #fff;
            background-color: #145f97 !important;
            border-color: #6c757d !important;
        }
        &:last-child {
            border-right: none;
            border-color: #6c757d !important;
        }
    }
    .acitve {
        color: #fff;
        background-color: #145f97;
        border-color: #6c757d !important;
    }
}

.sectionHeader {
    margin: 0px !important;
    border-bottom: 1px solid #ababab;
    background-color: transparent;
    min-height: 40px;
    margin-bottom: 0.3rem;
}
.panelHeader {
    h3 {
        font-size: 16px;
        font-weight: 500;
        margin: 0;
    }
}
