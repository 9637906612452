.pageWrapper {
    overflow: hidden;
    height: calc(100vh - 310px);
    section {
        padding: 0 4rem 4rem;
        overflow: scroll;
        height: calc(100vh - 350px);
        margin-top: 3rem;
      h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.87);
        margin: 3rem 0 1rem ;
      }
      h4 {
        color: #145f97;
      }
      p {
        color: #000000;
      }
      h4, p {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
      }
      a {
        color: #145f97 !important;
      }
    }
    
  }
  .pageWrapperWithoutLogin {
    overflow: hidden;
    height: calc(100vh - 110px);
    section {
        padding: 0 4rem 4rem;
        overflow: scroll;
        height: calc(100vh - 150px);
        margin-top: 3rem;
      h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.87);
        margin: 3rem 0 1rem ;
      }
      h4 {
        color: #145f97;
      }
      p {
        color: #000000;
      }
      h4, p {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
      }
    }
    
  }
  .contentNav {
    padding: 3rem 3rem 0;
    position: sticky;
    align-self: start;
    h5 {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.87);
        line-height: 17px;
        margin-bottom: 1rem;
    }
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
            border-left: 1px solid rgba(0, 0, 0, 0.87);
            padding-bottom: 0.5rem;
            a {
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 1px;
                color: #212121;
                display: block;
                transition: 0.4s ease;
                padding-left: 1rem;
                border-left: 3px solid transparent;
                &:hover, &:active{
                color: #145f97 !important;
                border-color: #145f97;
            }
        }  
        &:last-child {
            padding: 0;
        }
      }
    }
}

.footerNav {
  height: 104px;
  border-top: 1px solid #E4E4E4;
  border-bottom: 1px solid #E4E4E4;
  margin: 1rem 3rem 0 3rem;
  display: flex;
  align-items: center;
  div {
    width: 50%;
  }
  a {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    align-items: center;
    text-align: right;
    letter-spacing: 1px;
    color: #145f97 !important;
    cursor: pointer;
  }
}