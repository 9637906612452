@import "variables.scss";

.superAdmin-containerWrap {
    margin: $spacer;
    border-radius: $border-radius-lg;
    border-color: $border-color;
    border-width: $border-width;
    border-style: solid;
}

.table-header {
    font-size: $font-size-default;
    font-weight: $font-weight-medium;
    vertical-align: top;
    background-color: $table-head-bg;
    padding: $table-cell-padding;
    border-top: none;
    border-bottom: (1 * $table-border-width) solid $table-border-color;
    border-top-left-radius: $border-radius-lg;
    border-top-right-radius: $border-radius-lg;
    .small {
        font-size: 100%;
    }
}

.input-field-col {
    height: 30px;
    position: relative;
    top: 2px;
    &.number-input-width {
        width: 72px;
        padding: 0.2rem !important;
    }
}

.badge-button {
    font-size: $font-size-default;
    font-weight: $font-weight-normal;
    border: 1px solid;
    padding: $badge-padding-x $badge-pill-padding-x;
}

.table-listing-wra {
    font-size: $font-size-default;
    .list-group-item {
        padding: $badge-padding-x !important;
    }
    .list-group-flush,
    .list-group-item {
        &:last-child {
            border-bottom-left-radius: $border-radius-lg;
            border-bottom-right-radius: $border-radius-lg;
        }
    }
}
