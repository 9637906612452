@import "../../../assets/scss/variables.scss";

.uploadDocumentFileDrop {
    outline: none;
    box-shadow: none;
}
.dropzone{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #b1b1b1;
    outline: none;
    transition: border .24s ease-in-out;
    &:focus{
        border-color: $primary;;
    }
}
.dropzone-file-uploads-container {
    height: calc(100vh - 205px);
    overflow: hidden;
    box-shadow: none;
    &:focus,
    &:hover {
        outline-color: $primary;
        outline-style: $border-radius-xlg;
    }
}
