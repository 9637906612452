.documentSummaryPieChartBottom {
    .recharts-wrapper {
        width: 460px;
    }
    .recharts-legend-wrapper {
        height: auto !important;
        // bottom: 88px !important;
        position: relative !important;
        margin: auto;
    }
    ul {
        padding-left: 3rem !important;
        padding-top: 1.5rem !important;
        li {
            width: 45%;
            float: left;
            text-align: left;
            padding-bottom: 5px;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .documentSummaryPieChartBottom {
        .recharts-wrapper {
            overflow: scroll;
        }
    }
}
