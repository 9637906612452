.input-range__slider {
    background-color: #005aff;
    border: 1px solid #005aff;
}
.input-range__track--active {
    background-color: #005aff;
}
.input-range__slider:active {
    transform: scale(1.2);
}
