.isDragging {
  border: dashed 1px #939191db !important;
}

.dropArea {
  height: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionButtonInfo {
  margin-bottom: 0rem !important;
}