.netRentalIncomeChart {
    div > ul:first-child {
        width: 460px !important;
    }
    .recharts-wrapper {
        width: 460px !important;
    }
}
.rentalIncomePieChartListing {
    ul {
        li {
            width: 100% !important;
            .recharts-surface,
            svg {
                width: auto !important;
            }
        }
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1280px) {
    .netRentalIncomeChart {
        .recharts-wrapper {
            overflow: scroll;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .netRentalIncomeChart {
        .recharts-wrapper {
            overflow: scroll;
        }
    }
}
