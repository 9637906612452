.shortcutKeysInfo {
  table {
    thead th {
      font-weight: 600;
      border-bottom: 1px solid #ababab;
      border-top: none;
      background-color: transparent;
    }
    th,
    td {
      font-size: 14px;
      padding: 0 0.60rem;
      height: 39px;
      line-height: 38px;
    }
  }
  kbd {
    color: #212529;
    font-weight: 700;
    margin-right: 5px;
    padding: 0.2rem 0.4rem;
    border-radius: 0.2rem;
    background-color: #ffffff;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.33);
  }
}
