@import "../../../assets/scss/variables.scss";

.hs-form_free.hs-form__thankyou-message {
  font-size: 38px;
  text-align: center;
}
.hs-form_free.hs-form__thankyou-message .hs-form__virality__link {
  font-size: 18px;
  margin-top: 240px;
  line-height: 60px;
}
.hs-form_free.hs-form__thankyou-message .hs-form__virality__link__sproket {
  width: 60px;
  height: 60px;
}
.hs-form_free.hs-form__thankyou-message:before {
  display: block;
  content: url('https://js.hsformsqa.net/success-green.svg');
  width: 130px;
  height: 201px;
  padding-top: 28px;
  margin: 0 auto;
}

// hubsport
.formContainer {
  width: 100%;
  font-size: $font-size-xsmall;
  border: 1px solid $gray-400;
  border-radius: $border-radius-lg;
  height: calc(100vh - 200px);
}

.hubspot-paneHeader {
  width: 100%;
  padding: 6px 12px;
  border-bottom: 1px solid $gray-400;
  background-color: $table-head-bg;
  border-top-left-radius: $border-radius-lg;
  border-top-right-radius: $border-radius-lg;
  .heading-sm {
    font-size: 1.15rem;
    margin: 0rem;
  }
}

.closeIconButton {
  padding: 0rem !important;
  border-color: transparent;
  background-color: rgba($gray-400, 0.5);
  border-radius: $rounded-pill;
  &:hover,
  &:focus,
  &:active {
    background-color: $gray-400;
    border-color: transparent;
  }
  img,
  svg {
    width: 12px;
    position: relative;
    top: -2px;
  }
}

.hubspot-form-wrapper {
  height: calc(100vh - 245px);
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  z-index: 9;
  padding: $spacer / 5 $spacer / 2 $spacer $spacer;
  label {
    font-size: $font-size-xsmall;
    font-weight: $font-weight-normal;
    margin-bottom: $spacer / 5;
  }
  .hs-input {
    width: 100% !important;
    font-size: $input-font-size;
    color: $gray-700 !important;
    font-weight: $font-weight-normal;
    padding: $input-btn-padding-y $input-btn-padding-x / 2;
    border-radius: $input-border-radius-lg;
    border: (1 * $input-btn-border-width) solid $input-border-color;
    &:focus {
      color: $input-focus-color;
      border-color: $input-border-color;
      outline: none;
      box-shadow: none;
      background-color: $white;
    }
  }
  .hs-button {
    width: 97.5%;
    font-size: $btn-font-size;
    border-radius: $btn-border-radius;
    padding: $btn-padding-y $btn-padding-x;
    &:focus {
      background-color: $link-hover-color;
      border-color: $link-hover-color;
      outline: none;
      box-shadow: none;
    }
  }
  .form-columns-1,
  .form-columns-2,
  .hs-submit {
    margin-top: $spacer !important;
  }
  .inputs-list {
    overflow: hidden;
    margin: 0rem !important;
  }
  ul {
    list-style: none;
    padding: 0rem;
    li {
      padding: 0px 0 3px 2px;
      label {
        padding-left: 0rem;
        &.hs-form-checkbox-display {
          width: 100%;
          input[type="checkbox"],
          input[type="radio"] {
            width: auto;
            height: 13px;
            position: relative;
            top: 3px;
            float: left;
          }

          span {
            padding-left: 0.5rem;
            color: $input-color;
          }
        }
      }
    }
  }
  .legal-consent-container {
    .hs-form-booleancheckbox-display {
      input {
        width: 13px !important;
        height: 13px;
      }
      > span {
        position: relative;
        top: -4px;
      }
      p {
        line-height: normal;
      }
    }
  }
}
