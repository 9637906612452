.cardWrapper {
    border: 1px solid #F7F7F7;
    border-radius: 10px;
    padding: 0;
}

.bodyWrapper {
    height: calc(100vh - 200px);
    overflow-y: scroll;
}
.innerWrapper {
    width: 90%;
    margin: 0 auto;
}

.billingHistory {
    & div {
        position: relative;
        top: auto;
        right: auto;
    }
}