.row {
  color: red;
  border-color: #f5c2c7;
  margin: 2rem;
  padding: 1rem;
  border-radius: 7px;
  align-items: center;
}

.desciptionWrapper {
  width: 400px;
  height: 448px;
  border-right: solid 1px rgba(0, 0, 0, 0.125);
}
.title {
  font-family: Helvetica Neue;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.04em;
  text-align: center;
}

.subTitle {
  font-family: Helvetica Neue;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 34px;
  letter-spacing: 0.04em;
  text-align: center;
}

.description {
  width: 189px;
  height: 60px;
  margin-top: 3rem;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 140%;
  display: block;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  color: rgba(0, 0, 0, 0.87);
}

.errorBox {
  display: flex;
  flex-direction: column;
  height: 28rem;
  overflow: scroll;
}

.errorWrapper {
  margin: 0.5rem 0rem;
}
  