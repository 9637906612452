.chargeCodeHeader {
  font-size: 13px;
  font-weight: 600;
  margin: 0;
  background-color: #e5eeff;
  border-bottom: 1px solid #c0d7ea;
  position: relative;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  div {
    padding: 0.6rem 0.2rem;
    white-space: nowrap;
  }
  .widthId {
    width: 5%;
  }
  .widthChargeCode {
    width: 25%;
  }
  .widthTotalAmount {
    width: 20%;
  }
  .widthOptionDrop {
    width: 115px;
  }
}

.chargeCode {
  overflow: auto;
  height: calc(100vh - 302px);
  width: 98%;
  padding-right: 0px;
  flex-grow: 0;
  flex-basis: auto;
  .table {
    margin-bottom: 0px;
  }
  ul {
    margin-bottom: 0px;
    width: 100%;
    padding: 0rem;
    display: block;
    border-bottom: 1px solid #dee2e6;
    position: relative;
    li {
      padding: 0.4rem 0.2rem;
      font-size: 13px;
      vertical-align: middle;
      border-top: none;
      background-color: #ffffff;
      display: inline-flex;
      width: auto;
    }
    .widthId {
      width: 5%;
      display: inline-flex;
    }
    .widthChargeCode {
      width: 25%;
    }
    .widthTotalAmount {
      width: 20%;
    }
    .widthOptionDrop {
      // width: 20%;
    }
    .widthChargeCodeOption {
      width: 20%;
      display: inline-block;
    }
    .dottedIcon {
      position: absolute;
      right: 3px;
      top: 7px;
      width: 18px;
      cursor: pointer;
      padding: 0;
      display: flex;
      justify-content: center;
      button {
        border-radius: 30px;
      }
      div {
        padding: 0;
      }
    }
  }
}

.chargeCodeFooter {
  margin: 0.7rem 0 0;
  position: relative;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
  background-color: #ffffff;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
  > :not(:last-child) {
    margin-right: 0.55rem;
  }
}

.appContextMenu > div > {
  :nth-child(2) {
    right: 176px !important;
    left: auto !important;
    margin-top: 7px;
  }
}

.footerFixedBottom {
  position: sticky;
  width: 100%;
  bottom: 0px;
}

.iconButtonInfo {
  width: 32px;
  height: 33px;
  padding: 0px !important;
  border: 1px solid #ccc;
  svg {
    font-size: 14px;
  }
}
.widthChargeCodeDotted {
  width: 98%;
}