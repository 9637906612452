@import "../../../assets/scss/variables.scss";

.table-container-section {
    padding: 0rem 0.1rem 1rem $spacer !important;
}
.containerWrapper {
    border: 1px solid $gray-400;
    margin: 0rem 0.1rem 0rem $spacer;
    border-radius: $border-radius-lg;
    height: calc(100vh - 200px);
    box-shadow: none;
}
.disableSelectCheckbox {
    background: #9e9e9e3d;
    opacity: 0.4;
}
