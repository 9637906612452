.totalSummary {
  width: 100%;
  margin: auto;

  .amount {
    background-color: #f9f9f9;
  }

  .subtitle {
    font-size: 12px;
  }

  .title {
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 0rem;
    word-break: keep-all;
    letter-spacing: -0.05em;
  }
}

.summary_totalCol {
  clear: both;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.09);

  .card_space {
    margin: 2px;
  }

  .card_shadow {
    transition: 0.3s;
  }
}

.cardsFixBottom {
  position: sticky !important;
  bottom: 0px;
  width: 100%;
}
