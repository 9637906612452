.spriteIcons {
    width: 26px;
    height: 18px;
    transform: scale(0.84);
}
.spritePngIcons {
    background: url(../../../assets/images/clikai-sprite-png-icons.svg) no-repeat;
}
.settingsIcon {
    background-position: -0px -1px !important;
}
.logoutIcon {
    background-position: -26px -1px !important;
}
.extractedIcon {
    background-position: -56px -1px !important;
}
.addMoreTagsIcon {
    background-position: -84px -1px !important;
}
.renameIcon {
    background-position: -1px -28px !important;
}
.clearTaggingIcon {
    background-position: -28px -28px !important;
}
.deleteIcon {
    background-position: -56px -28px !important;
}
.needSupportIcon,
.emailIcon {
    background-position: -84px -28px !important;
}
.downloadModelIcon {
    background-position: -0px -56px !important;
}
.downloadXlsxIcon {
    background-position: -27px -56px !important;
}
.lockIcon {
    background-position: -84px -56px !important;
}
.folderIcon {
    background-position: -1px -82px !important;
}
.userIcon {
    background-position: -56px -56px !important;
}
.companyIcon {
    background-position: -25px -56px !important;
}