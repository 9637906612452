.summaryView {
    padding: 0;
}
.switchWrapper {
    padding: 0;
    margin-bottom: 10px;
}
.chartWrapper {
    height: calc(100vh - 252px);
    width: 99.8%;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-bottom: 1rem;
    h4 {
        font-size: 16px;
        padding: 1rem;
        text-align: center;
    }
}

.filterWrapper {
    padding: 0rem;
    justify-content: center;
    position: absolute;
    top: 3px;
    left: 3%;
    width: auto;
    .row {
        justify-content: center;
    }
}
.chart {
    div > ul:first-child {
        width: 460px !important;
        left: 0;
        right: 0;
        margin: 1rem auto auto !important;
    }
}
