.autocomplete {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid rgba(108, 107, 107, 0.19);
  background-color: white
}

.autocompleteBg {
  background-color: white
}

.autocompleteHighlight {
  background-color: rgba(241, 245, 255, 1);
}

.poweredByGoogleImg {
  height: 10px;
}

.googleLogoWrapper {
  display: flex;
  position: absolute;
  width: 95%;
  justify-content: flex-end;
  padding: 3px;
}

.helpText {
  font-size: x-small;
  color: #383838;
}

.addressWrapper {
  border: 1px dotted #989898;
  padding: 20px;
}