@import "../../../assets/scss/variables.scss";

.browseBtn {
  padding: $btn-padding-y 0.7rem;
  border: 1px solid #bdbdbd !important;
  position: relative;
  color: $primary;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(2%, #ffffff), to(#f2f2f2));
  background-image: linear-gradient(180deg, #ffffff 2%, #f2f2f2 100%);
  &:hover,
  &:active,
  &:focus {
    opacity: 0.88;
    color: $primary !important;
    border-color: $primary !important;
  }
  img {
    width: 18px;
    margin-right: 5px;
    position: relative;
    top: -2px;
  }
}
