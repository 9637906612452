.projectSlideTabPanel {
  position: relative;
  .nav-tabs {
    display: inline-table;
    background-color: #1d456d;
    z-index: 99;
    height: calc(100vh - 94px);
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    float: right;
    .nav-link {
      font-size: 14px;
      font-weight: 500;
      border: none;
      color: #212529;
      padding: 0;
      margin-right: 0px;
      position: relative;
      display: inline-flex;
      text-align: center;
      white-space: nowrap;
      border-radius: 0px;
      &:last-child {
        border: none;
        margin-right: 0rem;
      }
      &:hover,
      &:focus,
      &.active {
        background-color: #007bff;
        border-top-right-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
      }
    }
  }
}
