@import "../../../assets/scss/variables.scss";

.header {
  padding: 0.5rem $spacer;

  // brand logo
  .navbarBrand {
    display: flex;
    align-items: center;
    img {
      width: 52px;
    }
    strong {
      font-size: 15px;
      font-weight: $font-weight-medium;
    }
  }
  // account right side col
  .accountHeader {
    display: flex;
    flex-direction: row;

    .avatar {
      position: relative;
      .userBdrHover {
        border: 1px solid transparent;
        padding: 0.1rem 0.7rem 0.2rem 0.2rem;
        display: flex;
        align-items: center;
        &:hover {
          border: 1px solid $white;
          border-radius: $border-radius-lg;
        }
      }
      .avatarIcon {
        width: auto;
        float: left;
        img {
          width: 28px;
          position: relative;
          top: 1px;
        }
      }

      .clikUserName {
        padding: 0.5rem 1rem 0.3rem;
        .userNameText {
          font-size: 15px;
          font-weight: $font-weight-medium;
          line-height: 18px;
          display: flex;
        }
      }
      .dropdownMenuRight {
        float: right;
        top: 40px !important;
        right: 0px !important;
        left: auto !important;
        min-width: 12rem;
        z-index: 99999;
        transform: none !important;
      }
    }
  }
  .navbarHeaderIcons {
    width: 28px;
  }
  .whatsNew {
    position: relative;
    i {
      position: relative;
      cursor: pointer;
    }
    .bellText {
      background-color: $warning;
      padding: 0rem $spacer / 3;
      border-radius: $border-radius-xlg;
      font-size: 10px;
      color: $black;
      position: absolute;
      left: -38px;
      top: -2px;
      z-index: 1;
    }
    img {
      cursor: pointer;
    }
  }
  .tutorial {
    img {
      width: 28px;
      position: relative;
      top: -1px;
      cursor: pointer;
    }
  }
  .videoIcon {
    padding: 0px 5px;
  }
}
