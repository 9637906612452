svg[data-icon="caret-right"] {
    position: relative;
    left: 0px;
}
svg[data-icon="caret-down"] {
    position: relative;
    left: 3px;
}
.docContextMenuDropdown {
    .dropdown-menu {
        right: -1rem;
        top: 1rem;
    }
}
.status-report:hover + .status-hover {
    display: block;
    color: red;
}
.status-hover {
    display: none;
}

.documents-header-0 {
    min-width: 10px;
}
.documents-header-1 {
    min-width: 20px;
}
.documents-header-2 {
    width: 220px;
}

.status-hover {
    display: none;
}

.document-table {
    top: 20px;
}

.select-all-docs-checkbox {
    margin: 5px 20px;
}
.documentCheckbox {
    padding: 0 0.9rem;
    position: relative;
}
.fileIcon {
    margin-left: 2.8rem;
}
.childDocument .docTypeBedge {
    margin-left: 2rem;
}
.docCheckbox {
    display: block;
    width: 15px;
    height: 15px;
    cursor: pointer;
    box-shadow: 0 0 0 1px #9e9e9e inset;
    margin: 0;
    border-radius: 2px;
}

.docCheckbox input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 999;
    cursor: pointer;
    left: 0;
}

.docCheckbox input[type='checkbox']:checked~span {
    transform: rotate(45deg);
    width: 7px;
    margin-left: 5px;
    border-color: #24c78e;
    border-width: 2px;
    border-top-color: transparent;
    border-left-color: transparent;
    border-radius: 0;
    position: relative;
    top: -3px;
}

.docCheckbox span {
    display: block;
    width: inherit;
    height: inherit;
    border: solid 1px #9e9e9e;
    border-radius: 2px;
}
.infoBanner {
    padding-left: 0.7rem;
}
.hasChildDocument {
    border-bottom: none !important;
}
  