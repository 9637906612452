@import "../../../assets/scss/variables.scss";

.linkedInButtonWrapper {
  height: 52px;
  width: 240px;
  color: #2196F2 !important;
  opacity: 0.87;
  padding: 1px;
  box-shadow: none !important;
  border: 1px solid #2196F2;
  &:hover {
    box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3) !important;
  }

  @media (max-width: 768px) {
    margin-top: 12px;
  }

  .linkedInIconWrapper {
    width: 48px;
    height: 48px;
    line-height: 36px;
    padding: 4px;
    border-radius: $btn-border-radius !important;

    .linkedInLogo {
      width: 18px;
      height: 18px;
      margin-left: 2rem;
      margin-top: -4px;

      @media (max-width: 768px) {
        margin-left: 0rem;
      }
    }
  }

  .linkedInTextWrapper {
    font-size: 17px;
    line-height: 48px;
    @media (max-width: 768px) {
      margin-left: -23px;
      font-size: 14px;  
    }
  }
}
