.rendererDocument {
  clear: both;
  max-width: 100%;
  overflow-wrap: break-word;
  margin: auto;
  .clikUl {
    display: flow-root;
    padding-inline-start: 2rem;
    li + li {
      margin-top: 0.3rem;
    }
  }
}

.admonitionBlock {
  width: 100%;
  padding: 1.5rem 1.5rem 1.5rem 3.5rem;
  position: relative;
  .admonitionIcon {
    position: absolute;
    left: 1.5rem;
    top: 1.5rem;
  }
  p {
    margin-bottom: 0.5rem;
   }
}
.releaseNote .mt-5{
  margin-top:1rem !important;
}
.releaseNote .releaseVersion{
  font-weight:500;
  color:dodgerblue;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%
}
