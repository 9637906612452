@import "../../../assets/scss/variables.scss";

.summary_totalCol {
  clear: both;
  margin-left: 0rem;
  margin-right: 0rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  border-top: (1 * $table-border-width) solid $table-border-color;
  .card_space {
    margin: 2px;
  }
  .card_shadow {
    transition: 0.3s;
  }
  .amount {
    background-color: $light-blue;
  }
  .subtitle {
    font-size: $font-size-msmall;
  }
  .title {
    font-size: $font-size-xsmall;
    font-weight: $font-weight-medium;
    padding-bottom: 0rem;
    word-break: keep-all;
    letter-spacing: -0.05em;
  }
}
