.tileTooltip {
  font-size: 14px !important;
  letter-spacing: 0.2px;
  background-color: #848a90 !important;

  .cardBody {
    padding: 5px 7px !important;
  }

  .heading {
    font-weight: 600 !important;
    padding-bottom: 3px;
    border-bottom: 1px solid #a5a5a5 !important;
  }

  strong {
    font-weight: 600 !important;
  }
}

.tileInfo {
  max-width: 200px;
}
