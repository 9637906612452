.header {
  padding: 0.5rem 0.9rem !important;
}

.logo {
  a {
    color: #ffffff;
  }

  strong {
    font-size: 15px;
    font-weight: 600;
  }
}