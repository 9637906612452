.breadcrumbLink {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  display: inline-block;

  &:before {
    content: "/";
    color: black;
    margin: 0 2px;
  }
}

.breadcrumbWithoutLink {
  color: #145f97;
  cursor: pointer;
}

.breadcrumbContainer {
  display: flex;
  a {
    color: #145f97;
  }
}