/*.App {*/
/*  text-align: center;*/
/*}*/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.modal-header button:before {
  top: 15px !important;
  right: 9px !important;
  width: 30px 1 !important;
  height: 30px !important;
  border-radius: 50rem;
}
