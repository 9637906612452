.taggingInfo {
    display: inline-block;
    font-size: 12px;
    background: #f5f5f5;
    border-radius: 21px;
    border: 1px solid #c0c0c0;
    padding: 3px 5px;
    position: relative;
    span {
        padding: 0 5px;
    }
    svg {
        margin-left: 5px;
        cursor: pointer;
    }
    input {
        border: none;
    }
    small {
        width: 1px;
        background-color: #c0c0c0;
        display: inline-block;
        height: 100%;
        position: absolute;
        top: 0;
        margin-left: -2px;
    }
}
.deadLink {
    cursor: not-allowed;
    pointer-events: none;
}