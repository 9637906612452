
.SplitPane,
.Pane1 {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: stretch;
}

#gc-sortASC-tr, #gc-sortDEC-tr, #gc-sort-color-tr{
    display: none !important;
}

div[gcuielement='gcDropDownWindow'] span {
    padding: 4px !important;
    display: block;
    border-bottom: 1px solid #ffffff3d;
    background: #687179;
    color: #fff;
    height: 28px;
}

div[gcuielement='gcDropDownWindow'] span:hover {
    background: #ffffff;
    color: #697279;
}
div[gcUIElement="gcSpread"] .gc-list-control .gc-list-control-container .gc-list-control-item span {
    border-bottom: 1px solid hsla(0,0%,100%,.24);
    background: #687179;
    color: #fff;
    padding: 6px 10px !important;
    font-size: 14px !important;
    height: 32px;
    line-height: 19px !important;
    min-width: 180px !important;
}
div[gcUIElement="gcSpread"] .gc-list-control .gc-list-control-container .gc-list-control-item span:hover {
    background: #fff;
    color: #697279;
}
div[gcUIElement="gcSpread"] .gc-list-control .gc-list-control-container .gc-list-control-item.gc-list-control-selected-item span {
    background: #1d456d;
    color: #fff;
}
div[gcUIElement="gcSpread"] .gc-list-control .gc-list-control-container {
    max-height: 300px;
    overflow-y: auto;
}
.spreadsheet-side-toolbar {
    .toggle-switch {
        margin-bottom: -3px;
    }
}

.as-on-date {
    .react-date-picker__wrapper {
        border: none;
    }
    .react-calendar {
        max-width: 300px;
        margin-right: 20px;
    }
}