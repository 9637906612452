.analysisChartWrapper {
    height: calc(100vh - 140px);
    overflow-x: hidden;
    overflow-y: scroll;
    width: 99.8%;
    margin: 0 auto;
    flex-grow: 0;
    flex-basis: auto;
    svg {
        font-size: 12px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1280px) {
    .analysisChartWrapper {
        overflow: scroll;
    }
}

@media only screen and (max-width: 1024px) {
    .analysisChartWrapper {
        overflow: scroll;
    }
}
