.affixVerticalTabs {
  position: relative;
  z-index: 99999;
  &:before {
    content: '';
    position: absolute;
    width: 48px;
    height: 10px;
    right: -48px;
    top: -48px;
    background-color: #1d456d;
  }
  .nav-tabs {
    display: inline-table;
    background-color: #1d456d;
    z-index: 99;
    height: calc(100vh - 48px);
    position: fixed;
    right: 0px;
    top: 48px;
    bottom: 0px;
    width: 48px;
    padding-top: 70px;
    border-bottom: none !important;
    .active {
      background-color: #2196f2;
      border-top-right-radius: 0.3rem;
      border-bottom-right-radius: 0.3rem;
    }
    .nav-link {
      font-size: 14px;
      font-weight: 500;
      border: none;
      color: #212529;
      padding: 0;
      position: relative;
      display: block;
      width: 44px;
      min-height: 40px;
      margin-right: 4px;
      margin: 0px 0px 3px 0px !important;
      border-radius: 0px;
      border-top-right-radius: 0.3rem;
      border-bottom-right-radius: 0.3rem;
      &:last-child {
        border: none;
        margin-right: 0rem;
      }
      &:hover,
      &:focus,
      &.active {
        background-color: #2196f2;
        border-top-right-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
      }
    }
  }
}

a[data-rb-event-key='chargeCode'],
a[data-rb-event-key='unitStatus'],
a[data-rb-event-key='floorPlan'],
a[data-rb-event-key='analysis'],
a[data-rb-event-key='occupancy'],
a[data-rb-event-key='leaseType'],
a[data-rb-event-key='tenantType'],
a[data-rb-event-key='documentSummary'],
a[data-rb-event-key='occupancy'],
a[data-rb-event-key='leaseType'],
a[data-rb-event-key='tenantType'],
.affixVerticalNav {
  position: absolute;
  content: '';
  transform: translate(0%, -50%);
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 24px;
  background-position: center center;
}
a[data-rb-event-key='chargeCode'],
.chargeCode {
  background-image: url(../../../assets/images/charge-code.svg);
}
a[data-rb-event-key='unitStatus'],
.unitStatus {
  background-image: url(../../../assets/images/unit-status.svg);
}
a[data-rb-event-key='floorPlan'],
.floorPlan {
  background-image: url(../../../assets/images/floorPlan-unitType.svg);
}
a[data-rb-event-key='analysis'],
.analysis {
  background-image: url(../../../assets/images/analysis.png);
}
a[data-rb-event-key='occupancy'],
.occupancy {
  background-image: url(../../../assets/images/analysis.png);
}

a[data-rb-event-key='leaseType'],
.leaseType {
  background-image: url(../../../assets/images/analysis.png);
}
a[data-rb-event-key='tenantType'],
.tenantType {
  background-image: url(../../../assets/images/analysis.png);
}

a[data-rb-event-key='documentSummary'],
.documentSummary {
  background-image: url(../../../assets/images/document-summary.svg);
}
a[data-rb-event-key='occupancy'],
.occupancy {
  background-image: url(../../../assets/images/unit-status.svg);
}
a[data-rb-event-key='leaseType'],
.leaseType {
  background-image: url(../../../assets/images/lease-type.png);
}
a[data-rb-event-key='tenantType'],
.tenantType {
  background-image: url(../../../assets/images/tenant-type.svg);
}
