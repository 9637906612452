@import "variables.scss";

.modal-content {
  border: none !important;
  border-radius: $modal-content-border-radius !important;
}

.modal-header {
  padding: $modal-header-padding;
  border-color: $modal-header-border-color;
  background-color: $modal-header-bg;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  .modal-title {
    font-size: $modal-title;
    color: $white;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    top: -1px;
  }
  .close {
    color: $close-color;
    opacity: 0.7;
    text-shadow: none;
  }
  button {
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: $spacer;
      right: 10px;
      width: 30px;
      height: 30px;
      border-radius: $rounded-pill;
      background-color: transparent;
      z-index: -1;
    }
    &.close {
      position: relative;
      top: -1px;
      right: 0.3rem;
    }
    &:hover {
      &:before {
        background-color: rgba($gray-500, 0.3);
      }
    }
  }
}

.modal-footer {
  padding: $modal-header-padding;
  border-color: $modal-footer-border-color;
}

.modal-backdrop {
  background-color: $modal-backdrop-bg;
}
