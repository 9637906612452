@import "../../../assets/scss/variables.scss";

.badgeStatus {
  &.Default > div,
  &.Successful > div,
  &.Pending > div,
  &.Converted > div,
  &.Active > div,
  &.Failed > div {
    display: inline-block;
    font-size: $font-size-msmall;
    font-weight: $font-weight-medium;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    background-color: $white;
    background-image: none;
    border: 1px solid;
    padding: $badge-pill-padding-y $badge-pill-padding-x;
    border-radius: $border-radius-md;
  }
  &.Default > div {
    color: transparent;
    border-color: transparent;
  }
  &.Successful > div {
    color: $green;
    border-color: $green;
  }
  &.Pending > div {
    color: $yellow;
    border-color: $yellow;
  }
  &.Converted > div {
    color: $orange;
    border-color: $orange;
  }
  &.Active > div {
    color: $yellow;
    border-color: $yellow;
  }
  &.Failed > div {
    color: $danger;
    border-color: $danger;
  }
}

.tablelistpro {
  width: 100%;
  font-size: $font-size-xsmall;
  display: block;
  clear: both;
  ul {
    padding: 0px;
    margin: 0px;
    width: 100%;
  }
}
.tablelistpro .tableHeader {
  font-size: $font-size-xsmall;
  font-weight: $font-weight-medium;
  clear: both;
  width: 100%;
  display: flex;
  padding: $document-tag-padding-x 0rem;
  background-color: $table-head-bg;
  border-bottom: (1 * $table-border-width) solid $gray-400;
  align-items: center;
}
.tablelistpro .fixed {
  overflow: hidden;
  position: sticky;
  z-index: 1;
  top: 0;
  width: 100%;
  white-space: nowrap;
}
.tablelistpro ul li {
  clear: both;
  width: 100%;
  display: flex;
  padding: 0rem;
  align-items: center;
  border-bottom: (1 * $table-border-width) solid $border-color;
  background-color: rgba($gray-100, 0.5);
  
  border-right: (1 * $table-border-width) solid rgba($gray-100, 0.5);
  > ul {
    width: 100%;
    li {
      border-right: none !important;
    }
  }
}
.tablelistpro ul li:hover,
.tablelistpro ul li:focus,
.tablelistpro ul li:active,
.tablelistpro ul li.selectItem {
  transition-duration: 0.3s;
  background-color: $table-hover-bg;
  outline: none;
  cursor: pointer;
}
.tablelistpro ul li > ul li:hover,
.tablelistpro ul li > ul li.selectItem {
  background-color: $table-hover-bg !important;
}

.tablelistpro ul li.selectItem {  
  border-right: (1 * $table-border-width) solid rgba($gray-100, 0.5);
  border-left: (1 * $table-border-width) solid rgba($gray-100, 0.5);
  &.parent{
    border: (1 * $table-border-width) solid $primary;
    background-color: $white;
    border-radius: $border-radius;
    box-shadow: $box-shadow-blue;
  }
}

.tablelistpro ul li > ul li.selectItem {
  background-color: $table-hover-bg !important;
}
.multiGroupTagCollapse {
  background-color: $table-hover-bg !important;
  border-left: 1px solid $primary;
  box-shadow: $box-shadow-card;
}
.widthGrid1,
.widthGrid2,
.widthGrid3,
.widthGrid4,
.widthGrid5 {
  white-space: nowrap;
  padding: $document-tag-header-padding;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: space-between;
  justify-content: flex-start;
}
.docName {
  width: auto;
}

.tablelistpro .tableSearchHead {
    clear: both;
}

.widthGrid1 {
  // min-width: 40px;
  img {
    height: 30px;
  }
}
.widthGrid2 {
  width: 180px;
}
.widthGrid3 {
  min-width: 90px;
}
.widthGrid4 {
  min-width: 120px;
}
.widthGrid5 {
  min-width: 150px;
}
.widthGrid6 {
  min-width: 68px;
  button {
    float: right;
  }
}

.sort {
  cursor: pointer;
}

.visibleRow {
  position: relative;
}

.documentCheckbox {
  cursor: pointer;
  position: relative;
  margin: 0 0.9rem;
  box-sizing:border-box;
  position: absolute;
}


