@import "../../../assets/scss/variables.scss";

.badgeStatus {
  &.Default > div,
  &.Successful > div,
  &.Pending > div,
  &.Converted > div,
  &.Active > div,
  &.Failed > div {
    position: relative;
    min-width: 30px;
    display: inline-block;
    font-size: $font-size-msmall;
    font-weight: $font-weight-smedium;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    background-color: transparent;
    background-image: none;
    border: 1px solid;
    padding: $badge-pill-padding-y $badge-pill-padding-x;
    border-radius: $border-radius-md;
  }
  &.Default > div {
    color: transparent;
    border-color: transparent;
  }
  &.Successful > div {
    color: $green;
    border-color: $green;
  }
  &.Pending > div {
    color: $yellow;
    border-color: $yellow;
  }
  &.Converted > div {
    color: $orange;
    border-color: $orange;
  }
  &.Active > div {
    color: $yellow;
    border-color: $yellow;
  }
  &.Failed > div {
    color: $danger;
    border-color: $danger;
  }
}

.expandArrowIcon {
  min-width: 30px;
  position: relative;
  top: 2px;
  padding: 0.3rem 0.7rem 0.3rem 0.3rem;
  svg {
    font-size: $font-size-default;
    fill: $primary;    
    path{
      fill: $primary;
    }
  }
}

.checkboxColumn {
  min-width: 10px
}

.arrowWidth {
  width: $font-size-default;
  img {
    fill: $primary;
  }
  &.arrowLeft {
    position: relative;
    left: 3px;
  }
}

.arrowDown {
  position: relative;
  left: 4px;
  transform: rotate(90deg);
}

