@import "../../../assets/scss/variables.scss";

.notFoundWrapper {
    position: relative;
    height: 100vh
}

.notFoundWrapper .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.notfound {
    max-width: 520px;
    width: 100%;
    line-height: 1.4;
    text-align: center
}

.notfound .notFoundInner {
    position: relative;
    height: 200px;
    margin: 0 auto 20px;
    z-index: -1
}

.notfound .notFoundInner h1 {
    font-family: montserrat,sans-serif;
    font-size: 166px;
    font-weight: 200;
    margin: 0;
    color: $gray-600;
    text-transform: uppercase;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

.notfound .notFoundInner h2 {
    font-family: montserrat,sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    color: $gray-900;
    background: $white;
    padding: 5px 5px;
    margin: auto;
    display: inline-block;
    position: absolute;
    bottom: 23px;
    left: 0;
    right: 0;
    -webkit-transform: translate(0%, -38%);
    -ms-transform: translate(0%, -38%);
    transform: translate(0%, -38%);
    width: 70%;
}

.notfound a {
    font-family: montserrat,sans-serif;
    display: inline-block;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    padding: 13px 23px;
    background: $blue;
    font-size: 18px;
    -webkit-transition: .2s all;
    transition: .2s all
}

.notfound a:hover {
    color: $blue-hover;
    background: $white
}

@media only screen and (max-width: 767px) {
    .notfound .notFoundInner h1 {
        font-size:148px
    }
}

@media only screen and (max-width: 480px) {
    .notfound .notFoundInner {
        height:148px;
        margin: 0 auto 10px
    }

    .notfound .notFoundInner h1 {
        font-size: 86px
    }

    .notfound .notFoundInner h2 {
        font-size: 16px
    }

    .notfound a {
        padding: 7px 15px;
        font-size:$font-size-default
    }
}
