@import "../../../assets/scss/variables.scss";

.responsiveTopMargin {
    margin-top: 0.25rem;
    @media (min-width: 767px) {
        margin-top: 3rem;
    }
}
.clikaiTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 29px;
    line-height: 130%;
    color: #02075D;
    letter-spacing: 0.04em;
    margin-bottom: 2rem;
    span {
        font-weight: 700;
        display: block;
        font-size: 34px;
        margin-bottom: 0.6rem;
    }

    @media (max-width: 900px) {
        font-size: 1.1rem;
        text-align: center;
        margin-bottom: 0px;
        span {
            font-size: 1.5rem;
        }
    }
}
.clikFeatures {
    display: flex;
    padding: 0;
    margin: 1.5rem 0 0 0;
    justify-content: space-between;
    list-style: none;
    @media (max-width: 767px) {
        width: 76%;
        margin: 1.5rem auto 0;
    }
    li {
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: 0.02em;
        color: rgba(0, 0, 0, 0.72);
        position: relative;
        padding-left: 1rem;
        @media (max-width: 767px) {
            text-align: center;
            font-size: 0.7rem;
            border: 1px solid #ddd;
            margin: 0 0.3rem;
            padding: 0.3rem 0.6rem;
            &:before { 
                display: none;
            }
        }
        &:before {
            width: 8px;
            height: 8px;
            left: 0;
            top: 7px;
            background: rgba(0, 0, 0, 0.72);
            content: "";
            position: absolute;
            border-radius: 50%;
        }
    }
}
.clikaiSubTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: rgba(0, 0, 0, 0.87);
    @media (max-width: 900px) {
     font-size: 0.9rem;
     text-align: center;
    }
}

.clikaiBanner {
    margin-top: -34px;
    @media (max-width: 767px) {
        margin-top: 0;
    }
    img {
        width: 100%;
    }
}

.clikClientList {
    padding: 0px;
    list-style: none;
    margin: 0;
    flex-direction: row;
    li {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-line-pack: center;
        align-content: center;
        margin: 0px;
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
    }
    @media (max-width: 767px) {
        flex-direction: row; 
        flex-wrap: wrap;
        li {
            width: 50%;
            flex: auto;
            img {
                max-width: 100%;
                padding: 0.5rem;
            }
        }
     }
     @media only screen and (min-width: 768px) and (max-width: 900px)  {
        flex-direction: row; 
        flex-wrap: wrap;
        li {
            width: auto;
            flex: auto;
            img {
                max-width: 100%;
                padding: 0.5rem;
            }
        }
     }

}

.clikaiDesc {
    padding-top: $spacer;
    .clikaiDescItem {
        padding: 0rem 0.7rem;
        font-weight: $font-weight-normal;
        display: inline-block;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            display: block;
            top: 10px;
            right: -5px;
            width: 8px;
            height: 8px;
            border-radius: $rounded-pill;
            background-color: rgba($black, 0.5);
        }
        &:first-child {
            padding-left: 0rem;
        }
        &:last-child {
            padding-right: 0rem;
        }
        &:last-child {
            &:before {
                background-color: transparent;
            }
        }
    }
}

.heading {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.32px;
    color: #02075D;
    margin-top: 3rem;
    @media (max-width: 767px) {
        margin-top: 0;
        font-size: 1.4rem;
        line-height: initial;
        padding-bottom: 1rem;
    }
}

.bannerContainer {
    @media (max-width: 767px) {
        margin-top: 2rem;
    }
}