.paymentReminderRibbon {
    position: fixed;
    z-index: 1000;
    top: 0.5rem;
    left: 20%;
    width: 60%;
    button {
      font-size: 18px;
      padding-left: 0.2rem !important;
    }
  }
  .alertWarning {
    font-size: 12px;
    border-radius: 0.25rem;
    border: 1px solid transparent;
  }
