.btnInfo {
  font-size: 0.9rem;
  text-align: left !important;
  font-weight: 400 !important;
  padding: 0.7rem 1.4rem;
  border-right: 3px solid #17a2b8;

  &.disabled,
  &:disabled {
    background-color: #25c1da;
    border-right: 3px solid #117a8b;
    opacity: 0.99;
  }

  &:hover {
    border-right: 3px solid #117a8b;
  }
}
