.cardWrapper {
    margin: 3rem auto;
    width: 90%;
    background: #FFFFFF;
    border: 1px solid #F7F7F7;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    padding: 0;
    textarea {
        height: 218px;
        padding: 1rem;
    }
    button {
        width: 300px;
        font-size: 16px;
        margin-top: 1rem;
    }
}
