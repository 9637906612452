.analysisHeader {
  font-size: 13px;
  font-weight: 600;
  margin: 0;
  background-color: #e5eeff;
  border-bottom: 1px solid #c0d7ea;
  position: relative;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  div {
    padding: 0.6rem 0.4rem;
    white-space: nowrap;
  }
  .documentStatusHeader {
    width: 32.5%;
  }
  .sqFtHeader {
    width: 33%;
  }
  .percentHeader {
    width: 33%;
  }
}

.analysis {
  width: 98%;
  height: calc(100vh - 286px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 0px;
  flex-grow: 0;
  flex-basis: auto;
  .table {
    margin-bottom: 0px;
  }
  table {
    margin-bottom: 0px;
    td {
      padding: 0.6rem 0.4em;
      font-size: 13px;
      width: auto;
      vertical-align: middle;
      border-top: none;
      background-color: #ffffff;
      border-bottom: 1px solid #dee2e6;
    }
    .documentStatusHeader {
      width: 33%;
    }
    .sqFtHeader {
      width: 33.5%;
    }
    .percentHeader {
      width: 33%;
    }
  }
}

.analysischartWrapper {
  font-size: 12px;
  padding: 2rem 0 0rem;
}

.occupancyRRWrapper {
  height: calc(100vh - 142px);
  width: 99.8%;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 0.3rem;
}
@media only screen and (max-width: 1920px) {
  .analysis {
    height: calc(100vh - 540px);
    table {
      .percentHeader {
        width: 70px;
      }
    }
  }
}
@media only screen and (min-width: 1600px) and (max-width: 1680px) {
  .analysis {
    height: calc(100vh - 540px);
    table {
      .percentHeader {
        width: 65px;
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .analysis {
    height: calc(100vh - 540px);
    table {
      .percentHeader {
        width: 62px;
      }
    }
  }
}

@media only screen and (min-width: 1360px) and (max-width: 1366px) {
  .analysis {
    height: calc(100vh - 540px);
    table {
      .percentHeader {
        width: 60px;
      }
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1280px) {
  .analysis {
    height: calc(100vh - 540px);
    table {
      .percentHeader {
        width: 58px;
      }
    }
  }
  .analysischartWrapper {
    .recharts-wrapper {
      overflow: scroll;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .analysischartWrapper {
    .recharts-wrapper {
      overflow: scroll;
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1280px) {
  .analysisAffixchart {
      .recharts-wrapper {
          overflow: scroll;
      }
  }
}

@media only screen and (max-width: 1024px) {
  .analysisAffixchart {
      .recharts-wrapper {
          overflow: scroll;
      }
  }
}

