@import '../../../assets/scss/variables.scss';

.floorPlanHeader {
  .floorPlan {
    font-size: 13px;
  }
}

.floorPlan {
  overflow: auto;
  width: 100%;
  padding-right: 0px;
  border-collapse: collapse;
  overflow-x: scroll;
  height: calc(100vh - 200px);
  table {
    thead {
      width: 100%;
      th {
        font-size: 13px;
        padding: 0.4em 0.2rem;
        vertical-align: top;
        position: relative;
        height: 45px;
        position: sticky;
        top: 0;
        z-index: 1;
        vertical-align: top;
        small {
          margin-top: 19px;
          display: block;
          color: $primary;
          position: absolute;
          bottom: 10px;
        }
        &.leaseType {
          width: 140px;
        }
        &.action {
          width: 60px;
        }
        &.bedBaths {
          width: 70px;
        }
      }
    }
    tbody {
      width: 100%;
      tr {
        width: 100%;
        position: relative;
        &.parentRow {
          td {
            background-color: #dadcdd91;
          }
        }
        &.childRow {
          td {
            background-color: #d9dcdd3d;
          }
        }
        td {
          padding: 0.4em 0.2rem;
          font-size: $font-size-xsmall;
          line-height: 16px;
          vertical-align: middle;
          border-top: none;
          background-color: $white;
          border-bottom: (1 * $table-border-width) solid $table-border-color;
          height: 45px;
          > big {
            display: inline-block;
            width: 30px;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 6px 0;
          }

          > span {
            padding: 0 4px;
          }
          &:hover {
            small {
              display: block;
            }
          }
          small {
            padding: 0.2rem 0.3rem;
            color: #fff;
            display: block;
            position: absolute;
            margin-top: -34px;
            background: #ef3232de;
            border-radius: 4px;
            display: none;
            &::after {
              content: '';
              width: 0;
              height: 0;
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-top: 8px solid #f14f4d;
              left: 4px;
              bottom: -6px;
              position: absolute;
            }
            + input {
              border: 1px solid #ef3232de;
            }
            ~ div {
              box-shadow: 0 0 0 1px #ef3232de;
              border-radius: 4px;
            }
          }
          input {
            font-size: 13px;
            border: 1px solid #b0b0b0;
            border-radius: 0.3rem !important;
            font-weight: 400;
            outline: none;
            box-shadow: none !important;
            padding: 5px 3px;
          }
          div {
            font-size: 13px !important;
          }
          &.leaseType {
            width: 140px;
          }
          &.action {
            text-align: left;
            svg {
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
  .fPlan {
    small {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.overflowPadding {
  padding-right: 30px;
}

.floorPlanFooter {
  > :not(:last-child) {
    margin-right: 0.55rem;
  }
}
.pt8 {
  padding-top: 8px;
}

.floorPlanChartWrapper {
  overflow: hidden;
  overflow-y: scroll;
  height: calc(100vh - 313px);
  width: 100%;
  margin: 0 auto;
  padding-right: 0px;
  flex-grow: 0;
  padding: 1rem 0 0 1rem;
  flex-basis: auto;
  font-size: $font-size-msmall;
  svg {
    font-size: $font-size-msmall;
  }
}

.floorPlanChartWrapperBx {
  height: 400px;
  margin: 1.5rem 0 0 0;
}

.textField::-webkit-outer-spin-button,
.textField::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}

.firstRow {
  background: #cbcdcf;
  &:hover {
    background: #cbcdcf !important;
  }
}
