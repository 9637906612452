@import "../../../assets/scss/variables.scss";
.overlayView {
    position: absolute;
    width: 100% !important;
    top: 0;
    background-color: $light-blue;
    height: 100%;
    padding: 0;
    left: 0;
    display: flex !important;
    justify-content: center;
    align-items: center;
    div {
      font-size: $font-size-xsmall !important;
      min-height: auto;
    }
  }
  .overlayViewInputs {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    border: none !important;
    padding: 0;
    li {
      display: inline-flex;
      background: inherit;
    }
    .overlayViewOptions {
      display: inline-block !important;
      width: 32%;
      margin: 0 1px;
      padding: 2px;
    }
    .overlayViewButton {
      margin: 0 4px;
      width: 36px;
      border: (1 * $table-border-width) solid $table-border-color;
      height: 33px;
    }
    .closeIcon {
      img {
        width: 12px;
      }
    }
    .textDesciption {
      text-align: center;
      justify-content: center;
      font-size: $font-size-small;
    }
}