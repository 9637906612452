@import "../../../assets/scss/variables.scss";

.formContainer {
  width: 100%;
  font-size: $font-size-xsmall;
  display: block;
  border: 1px solid $gray-400;
  border-radius: $border-radius-lg;
  height: calc(100vh - 200px);
  box-shadow: none;
}

.addIconButton:disabled {
  opacity: 0.5;
}

.fileName {
  font-size: 13px;
  width: 310px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: monospace;
  font-stretch: wider;
  margin-top: 10px;
  overflow: hidden;

  img {
    width: 15px;
    height: 15px;
    margin-right: 3px;
    margin-top: -3px;
  }
}

.buttonHeader {
  min-height: 43px;
}