.tenantTypeChart {
    height: calc(100vh - 363px);
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 12px;
    padding-bottom: 1rem;
    padding-top: 1.5rem;
    .recharts-wrapper {
        width: 460px !important;
    }
    .recharts-default-legend {
        padding-top: 2rem !important;
    }
}
