@import "../../../assets/scss/variables.scss";

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.reactTableHeader {
  position: -webkit-sticky;
  position: sticky;
  z-index: 100;
  top: 0;
  background-color: $white;
}
